// @ts-nocheck
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Scalars = {
    Int: number,
    ID: string,
    String: string,
    Float: number,
    DatetimeString: any,
    Boolean: boolean,
    JSON: any,
}

export interface Company {
    activeUserCount: (Scalars['Int'] | null)
    activeUsers: (User[] | null)
    completedOnboarding: Scalars['Int']
    counts: CountType
    createdAt: (Scalars['DatetimeString'] | null)
    flywheels: (Flywheel[] | null)
    id: Scalars['ID']
    industry: (Industry | null)
    industryId: (Scalars['Int'] | null)
    invitedUserCount: (Scalars['Int'] | null)
    lastSeenUser: (User | null)
    logoS3Bucket: (Scalars['String'] | null)
    logoS3Key: (Scalars['String'] | null)
    name: Scalars['String']
    paymentPrompt: (Scalars['Int'] | null)
    stripeCustomerRef: (Scalars['String'] | null)
    stripeStatus: (Scalars['String'] | null)
    stripeSubscriptionRef: (Scalars['String'] | null)
    trialExpiryAt: (Scalars['DatetimeString'] | null)
    updatedAt: (Scalars['DatetimeString'] | null)
    userCompany: (UserCompany | null)
    __typename: 'Company'
}

export interface CountType {
    flywheelCount: Scalars['Int']
    metricsCheckedInCount: Scalars['Int']
    metricsTotalCount: Scalars['Int']
    __typename: 'CountType'
}

export type Currency = 'EUR' | 'GBP' | 'USD'

export interface CurrentDate {
    date: (Scalars['DatetimeString'] | null)
    userId: (Scalars['Int'] | null)
    __typename: 'CurrentDate'
}

export interface Flywheel {
    companyId: (Scalars['Int'] | null)
    createdAt: (Scalars['DatetimeString'] | null)
    currency: Scalars['String']
    deletedAt: (Scalars['DatetimeString'] | null)
    flywheelTemplateId: (Scalars['Int'] | null)
    id: Scalars['ID']
    latestFlywheelGoal: (FlywheelGoal | null)
    name: Scalars['String']
    ownerId: Scalars['Int']
    setupComplete: (Scalars['Int'] | null)
    steps: (Step[] | null)
    timeframe: Scalars['String']
    updateFrequency: Scalars['String']
    updatedAt: (Scalars['DatetimeString'] | null)
    __typename: 'Flywheel'
}

export interface FlywheelGoal {
    achieveBy: Scalars['DatetimeString']
    cap: (Scalars['Float'] | null)
    checkInSubgoal: (Subgoal | null)
    createdAt: (Scalars['DatetimeString'] | null)
    createdBy: Scalars['Int']
    flywheelId: Scalars['Int']
    fromDate: Scalars['DatetimeString']
    goal: Scalars['String']
    id: Scalars['ID']
    isHealthy: (Scalars['Boolean'] | null)
    latestTotalValue: Scalars['Float']
    name: Scalars['String']
    owner: (User | null)
    ownerId: Scalars['Int']
    subgoals: (Subgoal[] | null)
    toDate: (Scalars['DatetimeString'] | null)
    unitDescription: Scalars['String']
    unitDisplay: Scalars['String']
    unitName: Scalars['String']
    unitType: Scalars['String']
    unitTypeLabel: Scalars['String']
    updateFrequency: Scalars['String']
    updatedAt: (Scalars['DatetimeString'] | null)
    __typename: 'FlywheelGoal'
}

export interface FlywheelGoalUpdate {
    createdAt: (Scalars['DatetimeString'] | null)
    flywheelGoalId: Scalars['Int']
    id: Scalars['ID']
    isHealthy: (Scalars['Boolean'] | null)
    notes: (Scalars['String'] | null)
    startDate: Scalars['DatetimeString']
    subgoalId: Scalars['Int']
    updatedAt: (Scalars['DatetimeString'] | null)
    updatedBy: (User | null)
    value: Scalars['String']
    __typename: 'FlywheelGoalUpdate'
}

export interface FlywheelTemplate {
    createdAt: (Scalars['DatetimeString'] | null)
    id: Scalars['ID']
    info: (Scalars['String'] | null)
    name: Scalars['String']
    updatedAt: (Scalars['DatetimeString'] | null)
    __typename: 'FlywheelTemplate'
}

export interface Industry {
    id: Scalars['ID']
    name: Scalars['String']
    __typename: 'Industry'
}

export interface Metric {
    cap: (Scalars['Float'] | null)
    createdAt: (Scalars['DatetimeString'] | null)
    fromDate: Scalars['DatetimeString']
    id: Scalars['ID']
    isCheckInDue: Scalars['Boolean']
    isHealthy: (Scalars['Boolean'] | null)
    isReviewDue: Scalars['Boolean']
    metricUpdates: (MetricUpdate[] | null)
    name: Scalars['String']
    owner: (User | null)
    reportingWindowTiming: (Scalars['String'] | null)
    reviewers: (UserMetricReview[] | null)
    slug: (Scalars['String'] | null)
    stepId: Scalars['Int']
    targets: (MetricTarget[] | null)
    toDate: (Scalars['DatetimeString'] | null)
    unitDescription: Scalars['String']
    unitDisplay: (Scalars['String'] | null)
    unitName: Scalars['String']
    unitTargetType: Scalars['String']
    unitType: Scalars['String']
    unitTypeLabel: Scalars['String']
    updatedAt: (Scalars['DatetimeString'] | null)
    __typename: 'Metric'
}

export interface MetricTarget {
    createdAt: (Scalars['DatetimeString'] | null)
    createdBy: User
    fromDate: Scalars['DatetimeString']
    id: Scalars['ID']
    isCurrent: (Scalars['Boolean'] | null)
    isNext: (Scalars['Boolean'] | null)
    metricId: Scalars['Int']
    target: Scalars['String']
    toDate: (Scalars['DatetimeString'] | null)
    updatedAt: (Scalars['DatetimeString'] | null)
    __typename: 'MetricTarget'
}

export interface MetricUpdate {
    createdAt: (Scalars['DatetimeString'] | null)
    id: Scalars['ID']
    isHealthy: (Scalars['Boolean'] | null)
    metricId: Scalars['Int']
    metricTarget: (MetricTarget | null)
    metricTargetId: Scalars['Int']
    notes: (Scalars['String'] | null)
    startDate: Scalars['DatetimeString']
    updatedAt: (Scalars['DatetimeString'] | null)
    updatedBy: (User | null)
    value: Scalars['String']
    __typename: 'MetricUpdate'
}

export interface Mutation {
    batchCreate: Scalars['Boolean']
    bulkUpdateSubgoalsAndProgress: Subgoal[]
    createCompany: Company
    createFlywheelGoalAndSubgoals: FlywheelGoal
    createFlywheelTemplate: FlywheelTemplate
    createIndustry: Industry
    createMetricTarget: MetricTarget
    createMetricUpdate: Metric
    createMetricWithTarget: Flywheel
    createMetricWithTargetInReview: Metric
    createOrUpdateFlywheelGoalUpdate: FlywheelGoal
    createStep: Step
    createStepsWithMetrics: Flywheel
    createUser: User
    customiseFlywheel: Flywheel
    deleteCompany: (Company | null)
    deleteSelf: Scalars['String'][]
    deleteUserFromCompany: Scalars['String'][]
    editExistingFlywheelGoalUpdate: FlywheelGoal
    editExistingMetricUpdate: Metric
    inviteUser: User
    markCompanyAsOnboarded: Company
    markPaymentPrompt: Company
    reviewMetric: Metric
    setCurrentDate: CurrentDate
    setupFlywheel: (Flywheel | null)
    softDeleteFlywheel: Flywheel
    toggleActive: Metric
    updateCompany: Company
    updateCompanyTrialExpiry: Company
    updateFlywheel: Flywheel
    updateFlywheelGoal: FlywheelGoal
    updateFlywheelGoalAndSubgoals: FlywheelGoal
    updateLastSeenAt: Scalars['DatetimeString']
    updateMetric: Metric
    updateSelf: User
    updateSubgoal: Subgoal
    updateUser: User
    __typename: 'Mutation'
}

export interface Query {
    getCompany: (Company | null)
    getCurrentDate: (CurrentDate | null)
    getFlywheel: (Flywheel | null)
    getFlywheelReview: (Review | null)
    getFlywheelTemplate: FlywheelTemplate
    listCompanies: (Company[] | null)
    listFlywheels: (Flywheel[] | null)
    listIndustries: (Industry[] | null)
    listMetricsForSubgoal: (Metric[] | null)
    listUsers: (User[] | null)
    session: Session
    __typename: 'Query'
}

export interface Review {
    flywheelGoal: (FlywheelGoal | null)
    metrics: (Metric[] | null)
    reviewPeriod: (Scalars['String'] | null)
    subgoal: (Subgoal | null)
    __typename: 'Review'
}

export interface Session {
    avatarS3Bucket: (Scalars['String'] | null)
    avatarS3Key: (Scalars['String'] | null)
    createdAt: (Scalars['DatetimeString'] | null)
    email: (Scalars['String'] | null)
    fakeNowDate: (Scalars['DatetimeString'] | null)
    firstName: (Scalars['String'] | null)
    id: (Scalars['ID'] | null)
    isMetricOwner: Scalars['Boolean']
    jobTitle: (Scalars['String'] | null)
    lastName: (Scalars['String'] | null)
    lastSeenAt: (Scalars['DatetimeString'] | null)
    role: (Scalars['String'] | null)
    updatedAt: (Scalars['DatetimeString'] | null)
    userCompanies: (Company[] | null)
    userFlywheels: (Flywheel[] | null)
    verified: (Scalars['Int'] | null)
    __typename: 'Session'
}

export interface Step {
    alias: (Scalars['String'] | null)
    createdAt: (Scalars['DatetimeString'] | null)
    flywheelId: Scalars['Int']
    id: Scalars['ID']
    metrics: (Metric[] | null)
    name: Scalars['String']
    order: (Scalars['Int'] | null)
    ownerId: (Scalars['Int'] | null)
    updatedAt: (Scalars['DatetimeString'] | null)
    __typename: 'Step'
}

export interface Subgoal {
    createdAt: (Scalars['DatetimeString'] | null)
    endDate: Scalars['DatetimeString']
    flywheelGoalId: Scalars['Int']
    goal: Scalars['String']
    id: Scalars['ID']
    isCheckInDue: Scalars['Boolean']
    isHealthy: (Scalars['Boolean'] | null)
    latestProgress: Scalars['Float']
    latestUpdate: (FlywheelGoalUpdate | null)
    startDate: Scalars['DatetimeString']
    updatedAt: (Scalars['DatetimeString'] | null)
    updates: FlywheelGoalUpdate[]
    __typename: 'Subgoal'
}

export type Timeframe = 'HALF_YEARLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY'

export type UpdateFrequency = 'DAILY' | 'MONTHLY' | 'QUARTERLY' | 'WEEKLY'

export interface User {
    avatarS3Bucket: (Scalars['String'] | null)
    avatarS3Key: (Scalars['String'] | null)
    company: (Company | null)
    createdAt: (Scalars['DatetimeString'] | null)
    email: Scalars['String']
    firstName: (Scalars['String'] | null)
    id: Scalars['ID']
    isMetricOwner: Scalars['Boolean']
    jobTitle: (Scalars['String'] | null)
    lastName: (Scalars['String'] | null)
    lastSeenAt: (Scalars['DatetimeString'] | null)
    role: Scalars['String']
    status: Scalars['String']
    updatedAt: (Scalars['DatetimeString'] | null)
    userCompanies: (Company[] | null)
    userCompany: (UserCompany | null)
    userFlywheels: (Flywheel[] | null)
    verified: Scalars['Int']
    __typename: 'User'
}

export interface UserCompany {
    companyId: Scalars['ID']
    id: Scalars['ID']
    role: Scalars['String']
    __typename: 'UserCompany'
}

export type UserCompanyRole = 'ADMIN' | 'USER'

export interface UserMetricReview {
    createdAt: (Scalars['DatetimeString'] | null)
    id: Scalars['ID']
    updatedAt: (Scalars['DatetimeString'] | null)
    user: User
    userId: Scalars['ID']
    __typename: 'UserMetricReview'
}

export interface CompanyGenqlSelection{
    activeUserCount?: boolean | number
    activeUsers?: UserGenqlSelection
    completedOnboarding?: boolean | number
    counts?: CountTypeGenqlSelection
    createdAt?: boolean | number
    flywheels?: FlywheelGenqlSelection
    id?: boolean | number
    industry?: IndustryGenqlSelection
    industryId?: boolean | number
    invitedUserCount?: boolean | number
    lastSeenUser?: UserGenqlSelection
    logoS3Bucket?: boolean | number
    logoS3Key?: boolean | number
    name?: boolean | number
    paymentPrompt?: boolean | number
    stripeCustomerRef?: boolean | number
    stripeStatus?: boolean | number
    stripeSubscriptionRef?: boolean | number
    trialExpiryAt?: boolean | number
    updatedAt?: boolean | number
    userCompany?: UserCompanyGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CountTypeGenqlSelection{
    flywheelCount?: boolean | number
    metricsCheckedInCount?: boolean | number
    metricsTotalCount?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface CreateMetricWithTargetArgs {cap?: (Scalars['Float'] | null),fromDate: Scalars['String'],name: Scalars['String'],ownerEmail: Scalars['String'],reportingWindowTiming?: (Scalars['String'] | null),slug?: (Scalars['String'] | null),stepId: Scalars['Int'],target: Scalars['String'],toDate?: (Scalars['String'] | null),unitDescription: Scalars['String'],unitDisplay: Scalars['String'],unitName: Scalars['String'],unitTargetType: Scalars['String'],unitType: Scalars['String'],unitTypeLabel: Scalars['String']}

export interface CreateSubgoalArgs {endDate: Scalars['String'],goal: Scalars['String'],startDate: Scalars['String']}

export interface CurrentDateGenqlSelection{
    date?: boolean | number
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FlywheelGenqlSelection{
    companyId?: boolean | number
    createdAt?: boolean | number
    currency?: boolean | number
    deletedAt?: boolean | number
    flywheelTemplateId?: boolean | number
    id?: boolean | number
    latestFlywheelGoal?: FlywheelGoalGenqlSelection
    name?: boolean | number
    ownerId?: boolean | number
    setupComplete?: boolean | number
    steps?: StepGenqlSelection
    timeframe?: boolean | number
    updateFrequency?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FlywheelGoalGenqlSelection{
    achieveBy?: boolean | number
    cap?: boolean | number
    checkInSubgoal?: SubgoalGenqlSelection
    createdAt?: boolean | number
    createdBy?: boolean | number
    flywheelId?: boolean | number
    fromDate?: boolean | number
    goal?: boolean | number
    id?: boolean | number
    isHealthy?: boolean | number
    latestTotalValue?: boolean | number
    name?: boolean | number
    owner?: UserGenqlSelection
    ownerId?: boolean | number
    subgoals?: SubgoalGenqlSelection
    toDate?: boolean | number
    unitDescription?: boolean | number
    unitDisplay?: boolean | number
    unitName?: boolean | number
    unitType?: boolean | number
    unitTypeLabel?: boolean | number
    updateFrequency?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FlywheelGoalUpdateGenqlSelection{
    createdAt?: boolean | number
    flywheelGoalId?: boolean | number
    id?: boolean | number
    isHealthy?: boolean | number
    notes?: boolean | number
    startDate?: boolean | number
    subgoalId?: boolean | number
    updatedAt?: boolean | number
    updatedBy?: UserGenqlSelection
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface FlywheelGoalUpdateArgs {flywheelGoalId: Scalars['Int'],flywheelGoalUpdateId?: (Scalars['Int'] | null),startDate: Scalars['String'],subgoalId?: (Scalars['Int'] | null),value: Scalars['String']}

export interface FlywheelTemplateGenqlSelection{
    createdAt?: boolean | number
    id?: boolean | number
    info?: boolean | number
    name?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface IndustryGenqlSelection{
    id?: boolean | number
    name?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MetricGenqlSelection{
    cap?: boolean | number
    createdAt?: boolean | number
    fromDate?: boolean | number
    id?: boolean | number
    isCheckInDue?: boolean | number
    isHealthy?: boolean | number
    isReviewDue?: boolean | number
    metricUpdates?: MetricUpdateGenqlSelection
    name?: boolean | number
    owner?: UserGenqlSelection
    reportingWindowTiming?: boolean | number
    reviewers?: UserMetricReviewGenqlSelection
    slug?: boolean | number
    stepId?: boolean | number
    targets?: MetricTargetGenqlSelection
    toDate?: boolean | number
    unitDescription?: boolean | number
    unitDisplay?: boolean | number
    unitName?: boolean | number
    unitTargetType?: boolean | number
    unitType?: boolean | number
    unitTypeLabel?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MetricTargetGenqlSelection{
    createdAt?: boolean | number
    createdBy?: UserGenqlSelection
    fromDate?: boolean | number
    id?: boolean | number
    isCurrent?: boolean | number
    isNext?: boolean | number
    metricId?: boolean | number
    target?: boolean | number
    toDate?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MetricUpdateGenqlSelection{
    createdAt?: boolean | number
    id?: boolean | number
    isHealthy?: boolean | number
    metricId?: boolean | number
    metricTarget?: MetricTargetGenqlSelection
    metricTargetId?: boolean | number
    notes?: boolean | number
    startDate?: boolean | number
    updatedAt?: boolean | number
    updatedBy?: UserGenqlSelection
    value?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface MetricWithoutStepIdArgs {cap?: (Scalars['Float'] | null),fromDate: Scalars['String'],name: Scalars['String'],ownerEmail: Scalars['String'],reportingWindowTiming?: (Scalars['String'] | null),slug?: (Scalars['String'] | null),target: Scalars['String'],toDate?: (Scalars['String'] | null),unitDescription: Scalars['String'],unitDisplay: Scalars['String'],unitName: Scalars['String'],unitTargetType: Scalars['String'],unitType: Scalars['String'],unitTypeLabel: Scalars['String']}

export interface MutationGenqlSelection{
    batchCreate?: { __args: {completedOnboarding?: (Scalars['Int'] | null), metrics: CreateMetricWithTargetArgs[]} }
    bulkUpdateSubgoalsAndProgress?: (SubgoalGenqlSelection & { __args: {flywheelGoalId: Scalars['Int'], progressUpdates: FlywheelGoalUpdateArgs[], subgoalGoalValue?: (Scalars['String'] | null)} })
    createCompany?: (CompanyGenqlSelection & { __args: {industryId: Scalars['Int'], name: Scalars['String']} })
    createFlywheelGoalAndSubgoals?: (FlywheelGoalGenqlSelection & { __args: {achieveBy: Scalars['String'], cap?: (Scalars['Float'] | null), flywheelGoalId?: (Scalars['Int'] | null), flywheelId: Scalars['Int'], fromDate: Scalars['String'], goal: Scalars['String'], name: Scalars['String'], ownerEmail: Scalars['String'], steps?: (StepWithMetricsArgs[] | null), subgoals: CreateSubgoalArgs[], toDate?: (Scalars['String'] | null), unitDescription: Scalars['String'], unitDisplay: Scalars['String'], unitName: Scalars['String'], unitType: Scalars['String'], unitTypeLabel: Scalars['String'], updateFrequency: UpdateFrequency} })
    createFlywheelTemplate?: (FlywheelTemplateGenqlSelection & { __args: {info: Scalars['String'], name: Scalars['String']} })
    createIndustry?: (IndustryGenqlSelection & { __args: {name: Scalars['String']} })
    createMetricTarget?: (MetricTargetGenqlSelection & { __args: {createdBy: Scalars['Int'], fromDate: Scalars['String'], metricId: Scalars['Int'], target: Scalars['String']} })
    createMetricUpdate?: (MetricGenqlSelection & { __args: {metricId: Scalars['Int'], metricTargetId: Scalars['Int'], notes?: (Scalars['String'] | null), startDate: Scalars['String'], value: Scalars['String']} })
    createMetricWithTarget?: (FlywheelGenqlSelection & { __args: {cap?: (Scalars['Float'] | null), companyId: Scalars['Int'], fromDate: Scalars['String'], name: Scalars['String'], ownerEmail: Scalars['String'], reportingWindowTiming?: (Scalars['String'] | null), slug?: (Scalars['String'] | null), stepId: Scalars['Int'], target: Scalars['String'], toDate?: (Scalars['String'] | null), unitDescription: Scalars['String'], unitDisplay: Scalars['String'], unitName: Scalars['String'], unitTargetType: Scalars['String'], unitType: Scalars['String'], unitTypeLabel: Scalars['String']} })
    createMetricWithTargetInReview?: (MetricGenqlSelection & { __args: {cap?: (Scalars['Float'] | null), companyId: Scalars['Int'], flywheelId: Scalars['Int'], name: Scalars['String'], ownerId: Scalars['Int'], reportingWindowTiming?: (Scalars['String'] | null), slug?: (Scalars['String'] | null), stepId: Scalars['Int'], subgoalId: Scalars['Int'], target: Scalars['String'], unitDescription: Scalars['String'], unitDisplay: Scalars['String'], unitName: Scalars['String'], unitTargetType: Scalars['String'], unitType: Scalars['String'], unitTypeLabel: Scalars['String']} })
    createOrUpdateFlywheelGoalUpdate?: (FlywheelGoalGenqlSelection & { __args: {flywheelGoalId: Scalars['Int'], flywheelGoalUpdateId?: (Scalars['Int'] | null), notes?: (Scalars['String'] | null), startDate: Scalars['String'], subgoalId?: (Scalars['Int'] | null), updatedBy: Scalars['Int'], value: Scalars['String']} })
    createStep?: (StepGenqlSelection & { __args: {flywheelId: Scalars['Int'], name: Scalars['String'], ownerId?: (Scalars['Int'] | null)} })
    createStepsWithMetrics?: (FlywheelGenqlSelection & { __args: {companyId: Scalars['Int'], completedOnboarding?: (Scalars['Int'] | null), setupComplete?: (Scalars['Int'] | null), steps: StepWithMetricsArgs[]} })
    createUser?: (UserGenqlSelection & { __args: {companyId?: (Scalars['Int'] | null), email: Scalars['String'], firstName?: (Scalars['String'] | null), jobTitle?: (Scalars['String'] | null), lastName?: (Scalars['String'] | null), role: UserCompanyRole, verified?: (Scalars['Int'] | null)} })
    customiseFlywheel?: (FlywheelGenqlSelection & { __args: {flywheelId: Scalars['Int'], name: Scalars['String'], steps: UpdateStepAliasArg[]} })
    deleteCompany?: (CompanyGenqlSelection & { __args: {id: Scalars['Int']} })
    deleteSelf?: boolean | number
    deleteUserFromCompany?: { __args: {companyId: Scalars['Int'], userId: Scalars['String']} }
    editExistingFlywheelGoalUpdate?: (FlywheelGoalGenqlSelection & { __args: {flywheelGoalUpdateId: Scalars['Int'], notes?: (Scalars['String'] | null), value?: (Scalars['String'] | null)} })
    editExistingMetricUpdate?: (MetricGenqlSelection & { __args: {metricUpdateId: Scalars['Int'], notes?: (Scalars['String'] | null), value?: (Scalars['String'] | null)} })
    inviteUser?: (UserGenqlSelection & { __args: {companyId: Scalars['Int'], email: Scalars['String'], firstName?: (Scalars['String'] | null), jobTitle?: (Scalars['String'] | null), lastName?: (Scalars['String'] | null), role: UserCompanyRole} })
    markCompanyAsOnboarded?: (CompanyGenqlSelection & { __args: {companyId: Scalars['Int']} })
    markPaymentPrompt?: (CompanyGenqlSelection & { __args: {companyId: Scalars['Int'], shouldPrompt: Scalars['Boolean']} })
    reviewMetric?: (MetricGenqlSelection & { __args: {companyId?: (Scalars['Int'] | null), disabled?: (Scalars['Boolean'] | null), flywheelId: Scalars['Int'], metricId: Scalars['Int'], ownerId?: (Scalars['Int'] | null), subgoalId: Scalars['Int'], target?: (Scalars['String'] | null)} })
    setCurrentDate?: (CurrentDateGenqlSelection & { __args?: {currentDate?: (Scalars['String'] | null)} })
    setupFlywheel?: (FlywheelGenqlSelection & { __args: {companyId?: (Scalars['Int'] | null), currency: Currency, flywheelId?: (Scalars['Int'] | null), flywheelTemplateId?: (Scalars['Int'] | null), name: Scalars['String'], timeframe: Timeframe, updateFrequency: UpdateFrequency} })
    softDeleteFlywheel?: (FlywheelGenqlSelection & { __args: {flywheelId: Scalars['Int']} })
    toggleActive?: (MetricGenqlSelection & { __args: {disabled?: (Scalars['Boolean'] | null), metricId: Scalars['Int']} })
    updateCompany?: (CompanyGenqlSelection & { __args: {companyId: Scalars['Int'], industryId?: (Scalars['Int'] | null), logoS3Bucket?: (Scalars['String'] | null), logoS3Key?: (Scalars['String'] | null), name?: (Scalars['String'] | null)} })
    updateCompanyTrialExpiry?: (CompanyGenqlSelection & { __args: {companyId: Scalars['Int'], trialExpiryAt: Scalars['String']} })
    updateFlywheel?: (FlywheelGenqlSelection & { __args: {currency?: (Currency | null), flywheelId: Scalars['Int'], flywheelTemplateId?: (Scalars['Int'] | null), name?: (Scalars['String'] | null), timeframe?: (Timeframe | null), updateFrequency?: (UpdateFrequency | null)} })
    updateFlywheelGoal?: (FlywheelGoalGenqlSelection & { __args: {flywheelGoalId: Scalars['Int'], goal?: (Scalars['String'] | null), ownerId?: (Scalars['Int'] | null), updateFrequency?: (UpdateFrequency | null)} })
    updateFlywheelGoalAndSubgoals?: (FlywheelGoalGenqlSelection & { __args: {achieveBy: Scalars['String'], cap?: (Scalars['Float'] | null), flywheelId: Scalars['Int'], fromDate: Scalars['String'], goal: Scalars['String'], id: Scalars['Int'], name: Scalars['String'], subgoals: CreateSubgoalArgs[], toDate?: (Scalars['String'] | null), unitDescription: Scalars['String'], unitDisplay: Scalars['String'], unitName: Scalars['String'], unitType: Scalars['String'], unitTypeLabel: Scalars['String'], updateFrequency: UpdateFrequency} })
    updateLastSeenAt?: boolean | number
    updateMetric?: (MetricGenqlSelection & { __args: {cap?: (Scalars['Float'] | null), companyId?: (Scalars['Int'] | null), id: Scalars['Int'], name?: (Scalars['String'] | null), ownerEmail?: (Scalars['String'] | null), reportingWindowTiming?: (Scalars['String'] | null), target?: (Scalars['String'] | null), unitDescription?: (Scalars['String'] | null), unitDisplay?: (Scalars['String'] | null), unitName?: (Scalars['String'] | null), unitTargetType?: (Scalars['String'] | null), unitType?: (Scalars['String'] | null), unitTypeLabel?: (Scalars['String'] | null)} })
    updateSelf?: (UserGenqlSelection & { __args?: {avatarS3Bucket?: (Scalars['String'] | null), avatarS3Key?: (Scalars['String'] | null), email?: (Scalars['String'] | null), firstName?: (Scalars['String'] | null), jobTitle?: (Scalars['String'] | null), lastName?: (Scalars['String'] | null)} })
    updateSubgoal?: (SubgoalGenqlSelection & { __args: {goal?: (Scalars['String'] | null), subgoalId: Scalars['Int']} })
    updateUser?: (UserGenqlSelection & { __args: {companyId?: (Scalars['Int'] | null), role: UserCompanyRole, userId: Scalars['Int']} })
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface QueryGenqlSelection{
    getCompany?: (CompanyGenqlSelection & { __args: {id: Scalars['Int']} })
    getCurrentDate?: CurrentDateGenqlSelection
    getFlywheel?: (FlywheelGenqlSelection & { __args: {flywheelId: Scalars['Int']} })
    getFlywheelReview?: (ReviewGenqlSelection & { __args: {flywheelId: Scalars['Int']} })
    getFlywheelTemplate?: (FlywheelTemplateGenqlSelection & { __args?: {flywheelId?: (Scalars['Int'] | null)} })
    listCompanies?: CompanyGenqlSelection
    listFlywheels?: (FlywheelGenqlSelection & { __args: {companyId: Scalars['Int']} })
    listIndustries?: IndustryGenqlSelection
    listMetricsForSubgoal?: (MetricGenqlSelection & { __args: {flywheelId: Scalars['Int'], subgoalId: Scalars['Int']} })
    listUsers?: (UserGenqlSelection & { __args: {companyId: Scalars['Int']} })
    session?: SessionGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface ReviewGenqlSelection{
    flywheelGoal?: FlywheelGoalGenqlSelection
    metrics?: MetricGenqlSelection
    reviewPeriod?: boolean | number
    subgoal?: SubgoalGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface SessionGenqlSelection{
    avatarS3Bucket?: boolean | number
    avatarS3Key?: boolean | number
    createdAt?: boolean | number
    email?: boolean | number
    fakeNowDate?: boolean | number
    firstName?: boolean | number
    id?: boolean | number
    isMetricOwner?: boolean | number
    jobTitle?: boolean | number
    lastName?: boolean | number
    lastSeenAt?: boolean | number
    role?: boolean | number
    updatedAt?: boolean | number
    userCompanies?: CompanyGenqlSelection
    userFlywheels?: FlywheelGenqlSelection
    verified?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StepGenqlSelection{
    alias?: boolean | number
    createdAt?: boolean | number
    flywheelId?: boolean | number
    id?: boolean | number
    metrics?: MetricGenqlSelection
    name?: boolean | number
    order?: boolean | number
    ownerId?: boolean | number
    updatedAt?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface StepWithMetricsArgs {alias?: (Scalars['String'] | null),flywheelId: Scalars['Int'],metrics: MetricWithoutStepIdArgs[],name: Scalars['String'],order: Scalars['Int']}

export interface SubgoalGenqlSelection{
    createdAt?: boolean | number
    endDate?: boolean | number
    flywheelGoalId?: boolean | number
    goal?: boolean | number
    id?: boolean | number
    isCheckInDue?: boolean | number
    isHealthy?: boolean | number
    latestProgress?: boolean | number
    latestUpdate?: FlywheelGoalUpdateGenqlSelection
    startDate?: boolean | number
    updatedAt?: boolean | number
    updates?: FlywheelGoalUpdateGenqlSelection
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UpdateStepAliasArg {name?: (Scalars['String'] | null),order?: (Scalars['Int'] | null),stepId?: (Scalars['Int'] | null)}

export interface UserGenqlSelection{
    avatarS3Bucket?: boolean | number
    avatarS3Key?: boolean | number
    company?: (CompanyGenqlSelection & { __args?: {companyId?: (Scalars['Int'] | null)} })
    createdAt?: boolean | number
    email?: boolean | number
    firstName?: boolean | number
    id?: boolean | number
    isMetricOwner?: boolean | number
    jobTitle?: boolean | number
    lastName?: boolean | number
    lastSeenAt?: boolean | number
    role?: boolean | number
    status?: boolean | number
    updatedAt?: boolean | number
    userCompanies?: CompanyGenqlSelection
    userCompany?: UserCompanyGenqlSelection
    userFlywheels?: FlywheelGenqlSelection
    verified?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserCompanyGenqlSelection{
    companyId?: boolean | number
    id?: boolean | number
    role?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}

export interface UserMetricReviewGenqlSelection{
    createdAt?: boolean | number
    id?: boolean | number
    updatedAt?: boolean | number
    user?: UserGenqlSelection
    userId?: boolean | number
    __typename?: boolean | number
    __scalar?: boolean | number
}


    const Company_possibleTypes: string[] = ['Company']
    export const isCompany = (obj?: { __typename?: any } | null): obj is Company => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCompany"')
      return Company_possibleTypes.includes(obj.__typename)
    }
    


    const CountType_possibleTypes: string[] = ['CountType']
    export const isCountType = (obj?: { __typename?: any } | null): obj is CountType => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCountType"')
      return CountType_possibleTypes.includes(obj.__typename)
    }
    


    const CurrentDate_possibleTypes: string[] = ['CurrentDate']
    export const isCurrentDate = (obj?: { __typename?: any } | null): obj is CurrentDate => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isCurrentDate"')
      return CurrentDate_possibleTypes.includes(obj.__typename)
    }
    


    const Flywheel_possibleTypes: string[] = ['Flywheel']
    export const isFlywheel = (obj?: { __typename?: any } | null): obj is Flywheel => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFlywheel"')
      return Flywheel_possibleTypes.includes(obj.__typename)
    }
    


    const FlywheelGoal_possibleTypes: string[] = ['FlywheelGoal']
    export const isFlywheelGoal = (obj?: { __typename?: any } | null): obj is FlywheelGoal => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFlywheelGoal"')
      return FlywheelGoal_possibleTypes.includes(obj.__typename)
    }
    


    const FlywheelGoalUpdate_possibleTypes: string[] = ['FlywheelGoalUpdate']
    export const isFlywheelGoalUpdate = (obj?: { __typename?: any } | null): obj is FlywheelGoalUpdate => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFlywheelGoalUpdate"')
      return FlywheelGoalUpdate_possibleTypes.includes(obj.__typename)
    }
    


    const FlywheelTemplate_possibleTypes: string[] = ['FlywheelTemplate']
    export const isFlywheelTemplate = (obj?: { __typename?: any } | null): obj is FlywheelTemplate => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isFlywheelTemplate"')
      return FlywheelTemplate_possibleTypes.includes(obj.__typename)
    }
    


    const Industry_possibleTypes: string[] = ['Industry']
    export const isIndustry = (obj?: { __typename?: any } | null): obj is Industry => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isIndustry"')
      return Industry_possibleTypes.includes(obj.__typename)
    }
    


    const Metric_possibleTypes: string[] = ['Metric']
    export const isMetric = (obj?: { __typename?: any } | null): obj is Metric => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMetric"')
      return Metric_possibleTypes.includes(obj.__typename)
    }
    


    const MetricTarget_possibleTypes: string[] = ['MetricTarget']
    export const isMetricTarget = (obj?: { __typename?: any } | null): obj is MetricTarget => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMetricTarget"')
      return MetricTarget_possibleTypes.includes(obj.__typename)
    }
    


    const MetricUpdate_possibleTypes: string[] = ['MetricUpdate']
    export const isMetricUpdate = (obj?: { __typename?: any } | null): obj is MetricUpdate => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMetricUpdate"')
      return MetricUpdate_possibleTypes.includes(obj.__typename)
    }
    


    const Mutation_possibleTypes: string[] = ['Mutation']
    export const isMutation = (obj?: { __typename?: any } | null): obj is Mutation => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isMutation"')
      return Mutation_possibleTypes.includes(obj.__typename)
    }
    


    const Query_possibleTypes: string[] = ['Query']
    export const isQuery = (obj?: { __typename?: any } | null): obj is Query => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isQuery"')
      return Query_possibleTypes.includes(obj.__typename)
    }
    


    const Review_possibleTypes: string[] = ['Review']
    export const isReview = (obj?: { __typename?: any } | null): obj is Review => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isReview"')
      return Review_possibleTypes.includes(obj.__typename)
    }
    


    const Session_possibleTypes: string[] = ['Session']
    export const isSession = (obj?: { __typename?: any } | null): obj is Session => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSession"')
      return Session_possibleTypes.includes(obj.__typename)
    }
    


    const Step_possibleTypes: string[] = ['Step']
    export const isStep = (obj?: { __typename?: any } | null): obj is Step => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isStep"')
      return Step_possibleTypes.includes(obj.__typename)
    }
    


    const Subgoal_possibleTypes: string[] = ['Subgoal']
    export const isSubgoal = (obj?: { __typename?: any } | null): obj is Subgoal => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isSubgoal"')
      return Subgoal_possibleTypes.includes(obj.__typename)
    }
    


    const User_possibleTypes: string[] = ['User']
    export const isUser = (obj?: { __typename?: any } | null): obj is User => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUser"')
      return User_possibleTypes.includes(obj.__typename)
    }
    


    const UserCompany_possibleTypes: string[] = ['UserCompany']
    export const isUserCompany = (obj?: { __typename?: any } | null): obj is UserCompany => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserCompany"')
      return UserCompany_possibleTypes.includes(obj.__typename)
    }
    


    const UserMetricReview_possibleTypes: string[] = ['UserMetricReview']
    export const isUserMetricReview = (obj?: { __typename?: any } | null): obj is UserMetricReview => {
      if (!obj?.__typename) throw new Error('__typename is missing in "isUserMetricReview"')
      return UserMetricReview_possibleTypes.includes(obj.__typename)
    }
    

export const enumCurrency = {
   EUR: 'EUR' as const,
   GBP: 'GBP' as const,
   USD: 'USD' as const
}

export const enumTimeframe = {
   HALF_YEARLY: 'HALF_YEARLY' as const,
   MONTHLY: 'MONTHLY' as const,
   QUARTERLY: 'QUARTERLY' as const,
   YEARLY: 'YEARLY' as const
}

export const enumUpdateFrequency = {
   DAILY: 'DAILY' as const,
   MONTHLY: 'MONTHLY' as const,
   QUARTERLY: 'QUARTERLY' as const,
   WEEKLY: 'WEEKLY' as const
}

export const enumUserCompanyRole = {
   ADMIN: 'ADMIN' as const,
   USER: 'USER' as const
}
