import {
  UserRole,
  ReviewPeriod
} from "@roda/shared/types";
import {
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import { ReviewMetricCard } from "~/components/review/ReviewMetricCard";
import { ReviewStatusDots } from "~/components/review/ReviewStatusDots";
import { Spacer } from "~/components/Spacer";
import { StepNavigation } from "~/components/StepNavigation";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { FlywheelReviewMetricData } from "~/hooks/flywheel/use-get-flywheel-review";
import { useIsMobile } from "~/hooks/useIsMobile";
import { AddMetricPopup } from "~/pages/review/AddMetricPopup";
import { ReviewMetricTargetPopup } from "~/pages/review/ReviewMetricTargetPopup";
import dayjs from "~/utils/dayjs";

export const ReviewMetrics = () => {
  const isMobile = useIsMobile();
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const { setHideMainSideNav } = useSideNavigation();

  // Flywheel related data
  const {
    flywheel, flywheelSubgoals, subgoalInReview, activeFlywheelReview
  } = useSelectedFlywheel();

  const { currentCompany, isAdmin } = useCurrentCompanyContext();
  // Selected step for step navigation
  const [ selectedStep, setSelectedStep ] = useState<NonNullable<typeof ownedStepsWithMetrics>[0] | null>(null);
  // Selected step for adding new metrics
  const [ newMetricStep, setNewMetricStep ] = useState<NonNullable<typeof ownedStepsWithMetrics>[0] | null>(null);
  // Selected metric for target change pop-up
  const [ selectedMetric, setSelectedMetric ] = useState<FlywheelReviewMetricData | undefined>();
  // Permissions checks
  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;
  const isMetricOwner = !!activeFlywheelReview?.metrics?.find(metric => metric.owner?.id === user?.id);
  // Div refs
  const stepsContainerRef = useRef<HTMLDivElement | null>(null);
  const selectedStepRef = useRef<HTMLDivElement | null>(null);
  const [ inViewRef ] = useInView();
  // Permissions to review
  const hasAccessToReview = (isAdmin || isRodaAdmin || isMetricOwner);
  // Permissions to review flywheel goal
  const hadAccessToReviewFlywheelGoal = isAdmin || isRodaAdmin;

  /**
   * Filter metrics based on permissions
   * Admins and Roda admins have access to check in all metrics
   * Metric owners only have access to the metrics they own - making sure to check the owner for
   * the quarter we're reviewing - not the current quarter!
   */
  const ownedStepsWithMetrics = useMemo(() => {
    // Map the steps and filter out metrics that the user does not own
    const stepsWithFilteredMetrics = flywheel?.steps?.map(step => {
      // Find the metrics in this step for the quarter we're reviewing - this accounts for new metrics!
      const reviewQuarterMetricsForStep = activeFlywheelReview?.metrics ? activeFlywheelReview?.metrics.filter(metric => metric.stepId === Number(step.id)) : [];
      // Include metrics removed for the quarter we're reviewing - we get these from the current flywheel
      // state because we want to show them to allow re-enabling
      const currentQuarterDisabledMetrics = step.metrics ? step.metrics.filter(metric => !reviewQuarterMetricsForStep.find(reviewMetric => reviewMetric.id === metric.id)) : [];

      const allAvailableMetricsForStep = [
        ...reviewQuarterMetricsForStep.map(reviewMetric => ({
          ...step.metrics?.find(m => m.id === reviewMetric.id),
          ...reviewMetric
        })),
        ...currentQuarterDisabledMetrics
      ];

      return {
        ...step,
        // If the user isn't an admin, filter to only metrics which they own
        metrics: ((isAdmin || isRodaAdmin) ? allAvailableMetricsForStep : allAvailableMetricsForStep.filter(metric => metric.owner?.id === user?.id)) ?? []
      };
    });

    // Then, filter out steps that have no metrics accessible to the user
    const filteredSteps = stepsWithFilteredMetrics?.filter(step => step.metrics.length > 0);

    return filteredSteps;
  }, [
    activeFlywheelReview?.metrics,
    flywheel?.steps,
    isAdmin,
    isRodaAdmin,
    user?.id
  ]);

  // Steps for step navigation
  const stepNavigationSteps = useMemo(() => ownedStepsWithMetrics?.filter(step => step.metrics?.length) ?? [], [ ownedStepsWithMetrics ]);

  // Get the review subgoal idx
  // If it's a next year review this will ALWAYS be the first one
  const reviewSubgoalIdx = useMemo(() => {
    const index = activeFlywheelReview?.reviewPeriod === ReviewPeriod.NEXT_YEAR ? 0 : flywheelSubgoals && subgoalInReview ? flywheelSubgoals.findIndex(subgoal => subgoal.id === subgoalInReview.id) : undefined;

    return index !== -1 ? index : undefined;
  }, [
    activeFlywheelReview?.reviewPeriod,
    flywheelSubgoals,
    subgoalInReview
  ]);

  // On render of this page - always hide the main side nav
  useEffect(() => {
    setHideMainSideNav(true);
  }, [ setHideMainSideNav ]);

  // Scroll to the associated div when selectedStep changes
  useEffect(() => {
    if (selectedStep && selectedStepRef.current) {
      selectedStepRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ selectedStep ]);

  const handleViewMetric = (metricId: string) => {
    navigate(routes.reviewMetric(metricId, currentCompany ? currentCompany.id : undefined));
  };

  useEffect(() => {
    if (!hasAccessToReview) navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
  }, [
    currentCompany,
    hasAccessToReview,
    navigate
  ]);

  return (
    <div className="max-h-container h-container">
      <div
        className="flex flex-col w-full sticky top-0 bg-white z-10 pt-3"
        id="metric-review-header"
      >
        {stepNavigationSteps.length > 1 && (
          <div className="items-center md:pt-1">
            <div className="sm:w-[80%] md:w-[70%] max-w-[900px] mx-auto">
              <div className="overflow-x-auto w-[100dvw] sm:w-[unset]">
                <StepNavigation
                  onClick={({ id }) => {
                    const found = stepNavigationSteps.find(navStep => navStep.id === id);

                    if (found) {
                      setSelectedStep(found);
                    }
                  }}
                  steps={stepNavigationSteps}
                  selectedStep={selectedStep || stepNavigationSteps[ 0 ]}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={`flex bg-brand-cold-metal-50 ${isMobile ? "flex-col" : "flex-row"} bg-white overflow-x-hidden`}>

        <div className="flex flex-col pb-3 w-full">

          <div className="w-full items-center flex flex-col">
            <div className="w-[90%] sm:w-[80%] md:w-[70%] max-w-[900px] mx-auto">
              <div
                className="flex flex-col items-center w-full overflow-x-hidden overflow-y-auto gap-y-3.5 sm:gap-y-7"
                ref={node => {
                  stepsContainerRef.current = node;
                  inViewRef(node);
                }}
              >
                <div className="items-start w-full">
                  <div className="flex flex-col gap-y-2 justify-between sm:pt-5">
                    <p className="font-semibold text-xl">{`Review your flywheel metrics for Q${reviewSubgoalIdx !== undefined ? reviewSubgoalIdx + 1 : 1} ${dayjs(subgoalInReview?.startDate).format("YYYY")}`}</p>

                    <p className="text-brand-cold-metal-500">Do you want to update your metrics?</p>
                  </div>
                </div>

                {stepNavigationSteps?.map(step => (
                  <div
                    className="flex flex-col gap-y-4 w-full scroll-mt-metric-review-header-size"
                    key={step.id}
                    ref={stepRef => (step.id === selectedStep?.id && (selectedStepRef.current = stepRef))}
                  >
                    <div className="flex flex-row justify-between items-end">
                      {step.metrics?.length !== 0 && <p className="font-semibold">{step.alias ?? step.name}</p>}

                      <ReviewStatusDots
                        step={step}
                        subgoalStartDate={dayjs(subgoalInReview?.startDate)}
                      />
                    </div>

                    <div className="flex flex-col gap-y-3">

                      {step.metrics?.map(metric => metric && (
                        <ReviewMetricCard
                          key={metric.id}
                          metric={metric}
                          onViewClick={metric => handleViewMetric(metric.id)}
                          onTargetChangeClick={metric => setSelectedMetric(metric)}
                        />
                      ))}
                    </div>

                  </div>

                ))}
              </div>

              {isMobile && (<Spacer />)}

              <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 my-5" : "flex-row mt-8 mb-4"}`}>
                <Button
                  title="Back"
                  className="bg-brand-cold-metal-200 text-brand-cold-metal-800"
                  onClick={() => {
                    hadAccessToReviewFlywheelGoal ?
                      navigate(routes.reviewFlywheelGoal(currentCompany ? currentCompany.id : undefined)) :
                      navigate(routes.review());
                  }}
                />

                <Button
                  title="Done"
                  onClick={() => {
                    setHideMainSideNav(false);
                    navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
                  }}
                />
              </div>

              {subgoalInReview && (
                <ReviewMetricTargetPopup
                  isOpen={!!selectedMetric}
                  metric={selectedMetric}
                  subgoalId={+subgoalInReview.id}
                  onClose={() => setSelectedMetric(undefined)}
                />
              )}

              {subgoalInReview && (
                <AddMetricPopup
                  isOpen={!!newMetricStep && (isAdmin || isRodaAdmin)}
                  step={newMetricStep}
                  subgoalId={Number(subgoalInReview.id)}
                  onClose={() => setNewMetricStep(null)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};