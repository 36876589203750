import { UserRole } from "@roda/shared/types";
import debounce from "lodash.debounce";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useIntercom } from "react-use-intercom";

import { MobileNavigation } from "~/components/MobileNavigation";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { DynamicSizeProvider } from "~/contexts/DynamicSizeContext";
import { SubscriptionProvider } from "~/contexts/SubscriptionContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useDynamicViewportHeightUnitFallback } from "~/hooks/useDynamicViewportHeightUnitFallback";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useUpdateLastSeenAt } from "~/hooks/user/use-update-last-seen-at";
import { useResizeObserverPolyfill } from "~/hooks/useResizeObserverPolyfill";

import { SideNavigation } from "./components/side-navigation/SideNavigation";
import { Pages } from "./pages";

export const App = () => {
  useResizeObserverPolyfill();
  useDynamicViewportHeightUnitFallback();
  const { authenticated } = useAuth();
  const { user } = useCurrentUser();
  const [ _, updateLastSeenAt ] = useUpdateLastSeenAt();
  const isMobile = useIsMobile();
  const { boot, update } = useIntercom();

  useEffect(() => {
    if (user) {
      if (user.email) {
        // If we have user info, add it to Intercom
        update({
          name: user?.firstName || undefined,
          email: user?.email
        });
      }

      // update user.lastSeenAt
      // debounce as to only call the fn once should the handlers fire in quick succession
      const handler = debounce(() => updateLastSeenAt({}), 1000);

      // page loads
      window.addEventListener("load", handler);
      // network comes back online
      window.addEventListener("online", handler);
      // revisits the tab
      window.addEventListener("visibilitychange", handler);

      return () => {
        window.removeEventListener("load", handler);
        window.removeEventListener("online", handler);
        window.removeEventListener("visibilitychange", handler);
      };
    } else {
      // Boot Intercom!
      boot();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ user ]);

  return (

    <SubscriptionProvider>
      <DynamicSizeProvider>
        <div className={`flex-1 flex ${isMobile ? "flex-col" : "flex-row"}`}>
          {authenticated && user && (user.role === UserRole.RODA_ADMIN || !!user.userCompanies?.length) ?
            (!isMobile ? <SideNavigation /> : <MobileNavigation />) :
            null}

          <Pages />

        </div>

        <Toaster
          toastOptions={{ duration: 7000 }}
        // discrete loading toast tucks neatly in height of header with less inset
          containerClassName="[&:has([role='status']:empty)]:!inset-2 lg:!inset-4"
        />
      </DynamicSizeProvider>
    </SubscriptionProvider>

  );
};