import { useTypedMutation } from "@roda/graphql/urql";

import { Company } from "~/fragments/index";

import type { MutationGenqlSelection } from "@roda/graphql/genql";

type Params = NonNullable<MutationGenqlSelection["updateCompanyTrialExpiry"]>["__args"];

export type UpdateCompanyResponse = NonNullable<NonNullable<ReturnType<typeof useUpdateCompanyTrialExpiry>["0"]["data"]>["updateCompanyTrialExpiry"]>;

export const useUpdateCompanyTrialExpiry = () => {
  return useTypedMutation((vars: Params) => ({
    updateCompanyTrialExpiry: {
      __args: vars,
      ...Company
    }
  }));
};