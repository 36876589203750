import { zodResolver } from "@hookform/resolvers/zod";
import {
  useEffect,
  useRef,
  useState
} from "react";
import {
  useForm,
  Controller
} from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import {
  AvatarControls,
  AvatarType
} from "~/components/AvatarControls";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Divider } from "~/components/Divider";
import { UserProfileSchema } from "~/components/form/formSchemas";
import { Input } from "~/components/form/Input";
import { Icon } from "~/components/Icon";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useDeleteUserfromCompany } from "~/hooks/user/use-delete-user-from-company";
import { useUpdateSelf } from "~/hooks/user/use-update-self";

import {
  UserAccountFieldType,
  UserAccountFieldDisplayText
} from "src/types";

export const UserProfile = () => {
  // Hooks for fetching the current user and updating user details
  const { user: currentUser, refetch } = useCurrentUser();
  const [ updateSelfRes, updateSelfReq ] = useUpdateSelf();
  const [ deleteSelfRes, deleteSelfFromCompanyReq ] = useDeleteUserfromCompany();
  const { logout } = useAuth();
  const isMobile = useIsMobile();
  const { currentCompany } = useCurrentCompanyContext();
  // State for managing update status and email verification modal visibility
  const [ showUpdateEmailWarning, setShowUpdateEmailWarning ] = useState(false);
  const [ showDeleteSelfWarning, setShowDeleteSelfWarning ] = useState(false);
  const { handleRodaError, assertGraphQLSuccess } = useError();
  const { setHideMainSideNav } = useSideNavigation();
  const navigate = useNavigate();

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  // Refs for input fields
  const refs = {
    [ UserAccountFieldType.FirstName ]: useRef<HTMLInputElement>(null),
    [ UserAccountFieldType.LastName ]: useRef<HTMLInputElement>(null),
    [ UserAccountFieldType.Email ]: useRef<HTMLInputElement>(null),
    [ UserAccountFieldType.JobTitle ]: useRef<HTMLInputElement>(null)
  };

  // Form hook for managing form state and interactions
  const {
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: {
      errors, isDirty, dirtyFields
    }
  } = useForm({
    resolver: zodResolver(UserProfileSchema),
    defaultValues: {
      // Set initial form values with user details
      firstName: currentUser?.firstName || "",
      lastName: currentUser?.lastName || "",
      email: currentUser?.email,
      jobTitle: currentUser?.jobTitle || ""
    },
    shouldFocusError: false
  });

  // Watch the email field for changes
  const watchedEmail = watch(UserAccountFieldType.Email);

  // Function for handling form submission
  const submitHandler = handleSubmit(async formData => {
    // Check if the email has been changed
    if (dirtyFields[ UserAccountFieldType.Email ] && watchedEmail !== currentUser?.email) {
    // Show email verification modal if email is changed
      setShowUpdateEmailWarning(true);

      return;
    }

    // Update user details via API request

    const response = await updateSelfReq(formData);

    if (response.data) {
      toast.success("Details updated successfully");

      // Update local state with new user details
      reset({
        firstName: response.data.updateSelf.firstName || "",
        lastName: response.data.updateSelf.lastName || "",
        jobTitle: response.data.updateSelf.jobTitle || "",
        email: response.data.updateSelf.email
      });
      refetch();
    } else if (response.error) {
      handleRodaError(response.error, "Failed to update user details");
    }
  }
  );

  // Function for handling email update and verification
  const handleUpdateEmail = async (shouldUpdate: boolean) => {
    const allFormValues = getValues();

    const {
      firstName, lastName, jobTitle, email
    } = allFormValues;

    const updatedValues = {
      firstName,
      lastName,
      jobTitle,
      email
    };

    // Update user details and logout if email is updated
    if (shouldUpdate) {
      // Update user details via API request
      const response = await updateSelfReq(updatedValues);

      if (response.data) {
        toast.success("Details updated successfully");
        // Reset the form with updated data
        reset(updatedValues);
      } else if (response.error) {
        handleRodaError(response.error, "Failed to update user details");
      }

      setShowUpdateEmailWarning(false);
      // Logout user
      logout();
    } else {
      setShowUpdateEmailWarning(false);
    }
  };

  const handleDeleteSelfFromCompany = () => {
    if (!currentCompany?.id || !currentUser?.id) {
      handleRodaError(new Error("Something went wrong. Please try again."), "Something went wrong. Please try again.");

      return;
    }

    deleteSelfFromCompanyReq({
      companyId: +currentCompany.id.toString(),
      userId: currentUser.id.toString()
    }).then(res => {
      assertGraphQLSuccess(res);
      refetch({ requestPolicy: "network-only" });
      navigate(routes.home);
      toast.success("Succesfully left company.");
    }).catch(e => {
      handleRodaError(e, "Something went wrong. Please try again.");
    });
  };

  return (
    <div className={`flex flex-col w-full flex-1 bg-white ${!isMobile && "px-10"}`}>
      {/* Breadcrumbs */}
      {!isMobile && (
        <Breadcrumbs
          crumbs={[ { label: "Profile" } ]}
        />
      )}

      <div className="flex flex-col items-center justify-start w-full flex-1">

        {/* Container for form */}
        <div className={`max-w-[900px] flex-1 w-full text-xs sm:text-sm flex flex-col px-5 ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>

          {/* Header */}
          <div className="flex flex-col w-full">
            <div className={`font-bold text-left pb-2 ${isMobile ? "text-lg" : "text-2xl"}`}>Profile</div>

            <p className={`text-brand-sub-text ${isMobile ? "text-md" : "text-lg"}`}>Change your profile and account settings here</p>
          </div>

          <Divider />

          <AvatarControls type={AvatarType.USER} />

          <Divider />

          <form
            onSubmit={submitHandler}
            className="flex-1 flex flex-col"
          >
            {/* Special handling for first name and last name so they appear together (on mobile all are in column) */}
            {!isMobile && (
              <div className="flex flex-row mb-6 gap-5">
                {([ "firstName", "lastName" ] as const).map(fieldKey => (
                  <Controller
                    key={fieldKey}
                    name={fieldKey}
                    control={control}

                    render={({ field }) => (
                      <div className="flex-1">
                        <Input
                          label={UserAccountFieldDisplayText[ fieldKey ]}
                          {...field}
                          ref={refs[ fieldKey ]}
                          value={field.value || ""}
                          hasError={!!errors[ fieldKey ]}
                          errorMessage={errors[ fieldKey ]?.message}
                          inputMode="text"
                        />
                      </div>
                    )}
                  />
                ))}
              </div>
            )}

            {/* Other Fields */}
            {Object.entries(UserProfileSchema.shape).map(([ key ]) => {
              if (!isMobile && (key === "firstName" || key === "lastName")) return null;
              const fieldKey = key as UserAccountFieldType;

              return (
                <div
                  key={key}
                  className="mb-6 flex flex-col md:w-1/2 md:pr-2"
                >
                  <Controller
                    name={fieldKey}
                    control={control}
                    render={({ field }) => (
                      <Input
                        label={UserAccountFieldDisplayText[ fieldKey ]}
                        {...field}
                        ref={refs[ fieldKey ]}
                        value={field.value || ""}
                        hasError={!!errors[ fieldKey ]}
                        errorMessage={errors[ fieldKey ]?.message}
                        subText={currentUser?.verified && fieldKey === UserAccountFieldType.Email && watchedEmail === currentUser?.email ? "This email address is verified" : undefined}
                        iconComponent={fieldKey === UserAccountFieldType.Email && currentUser?.verified && watchedEmail === currentUser.email ? (
                          <Icon.Verified
                            className="text-[#2BB762]"
                            size={20}
                          />
                        ) : undefined}
                      />
                    )}
                  />
                </div>
              );
            })}

            {isMobile && (<Spacer />)}

            <div className="flex flex-col gap-y-5">
              {/* Action buttons for saving or discarding changes */}
              {isDirty && (
                <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 pb-5" : "flex-row"}`}>
                  <Button
                    type="submit"
                    disabled={updateSelfRes.fetching || Object.keys(errors).length > 0}
                    loading={updateSelfRes.fetching}
                    title="Save"
                    className={`px-6 ${isMobile ? "order-first" : ""}`}
                  />

                  <Button
                    type="button"
                    onClick={() => reset()}
                    title="Discard"
                    disabled={updateSelfRes.fetching}
                    className="bg-brand-cold-metal-100 text-brand-cold-metal-900 hover:contrast-75"
                  />
                </div>
              )}

              <div className={`flex gap-x-3 self-start ${isMobile ? "flex-col w-full gap-3 pb-5" : "flex-row"}`}>
                <Button
                  type="button"
                  onClick={() => setShowDeleteSelfWarning(true)}
                  title={`Remove yourself from ${currentCompany?.name}`}
                  className=" bg-brand-error-red-400 text-brand-cold-metal-900 hover:contrast-75"
                />
              </div>
            </div>

          </form>
        </div>
      </div>

      {/* Email verification modal */}
      <ConfirmationPopup
        isOpen={showUpdateEmailWarning}
        onContinue={() => handleUpdateEmail(true)}
        onCancel={() => handleUpdateEmail(false)}
        loading={updateSelfRes.fetching}
        title="Email Verification Required"
        text="Updating your email address? You'll be briefly logged out for security. Don't worry, check your inbox for a new verification email to log back into your Roda account."
        iconComponent={(
          <Icon.MailOpen
            size={28}
            className="text-brand-cold-metal-700"
          />
        )}
      />

      <ConfirmationPopup
        isOpen={showDeleteSelfWarning}
        onContinue={handleDeleteSelfFromCompany}
        onCancel={() => setShowDeleteSelfWarning(false)}
        loading={deleteSelfRes.fetching}
        title="Are you sure?"
        text="Your account will be removed from this organisation immediately. Any metrics you own will be reassigned to an admin."
        continueText="Leave company"
        iconComponent={(
          <Icon.Bin
            size={28}
            className="text-brand-cold-metal-700"
          />
        )}
      />
    </div>
  );
};
