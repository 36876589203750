import {
  fetchImageFromUri,
  getFallbackFileName,
  signMediaURL
} from "@roda/shared/utils";
import {
  useRef,
  useState
} from "react";
import toast from "react-hot-toast";

import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { CompanyAvatar } from "~/components/CompanyAvatar";
import { Icon } from "~/components/Icon";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useUpdateCompany } from "~/hooks/company";
import { useError } from "~/hooks/useError";
import { useUpdateSelf } from "~/hooks/user";
import { resizeAndSmoothImage } from "~/utils/resizeAndSmoothImage";

import type { Company } from "@roda/graphql/genql";
import type React from "react";

export enum AvatarType {
  USER = "user",
  COMPANY = "company",
}

interface AvatarControlsProps {
  type?: AvatarType;
  company?: Company
}

const allowedImageTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/webp",
  "image/heic",
  "image/heif",
  "image/avif"
];

/**
 * Controls for uploading or removing a company or user avatar.
 * @param type - Required: Type of the avatar, either 'user' or 'company'.
 * @returns {React.ReactElement} - Rendered component for avatar controls.
 */
export const AvatarControls: React.FC<AvatarControlsProps> = ({ type, company }): React.ReactElement => {
  const {
    user, refetch, fetching
  } = useCurrentUser();

  const { handleRodaError } = useError();
  const [ isLoading, setIsLoading ] = useState(false);
  const folderName = type === AvatarType.USER ? "user-avatars" : "company-logos";
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [ _, updateCompanyReq ] = useUpdateCompany();
  const [ __, updateSelfReq ] = useUpdateSelf();
  const { currentCompany } = useCurrentCompanyContext();
  const hasAvatar = type === AvatarType.USER ? user?.avatarS3Key : company?.logoS3Key || currentCompany?.logoS3Key;

  /**
   * Handle uploading an image
   * @param image - The image file to upload
   */
  const handleUploadImage = async (image: File | undefined) => {
    if (image) {
      setIsLoading(true);

      try {
        // Check file size and format
        if (image.size > 50000000) {
          handleRodaError("Image is too large. Please select an image under 50MB.", "Image is too large. Please select an image under 50MB.");
          setIsLoading(false);

          return;
        }

        // Check file type, if not allowed, show error
        if (!allowedImageTypes.includes(image.type)) {
          handleRodaError("Invalid image format. Please select a different image format.", "Invalid image format. Please select a different image format.");
          setIsLoading(false);

          return;
        }
        // Resize image to 300x300
        const resizedImage = await resizeAndSmoothImage(image, 300);

        if (!resizedImage) {
          handleRodaError("Failed to resize image", "Failed to resize image");
          setIsLoading(false);

          return;
        }
        // Get file URL and blob
        const fileURL = URL.createObjectURL(resizedImage).replaceAll(" ", "-");
        const file = await fetchImageFromUri(fileURL);

        // Sign for upload URL
        const {
          key, uploadURL, bucket
        } = await signMediaURL({
          baseURL: import.meta.env.VITE_BASE_URL,
          key: `${folderName}/${new Date().toISOString()}-${image.name.replaceAll(" ", "-") || getFallbackFileName(fileURL)}`,
          methods: [ "get", "put" ],
          options: {
            contentType: image.type,
            acl: "public-read"
          }
        });

        if (uploadURL && file) {
          // Upload to S3
          await fetch(uploadURL, {
            method: "PUT",
            body: file,
            headers: { "Content-Type": image.type as string }
          }).catch(error => {
            handleRodaError(error, "Failed to upload image");
            setIsLoading(false);

            return;
          }
          );
        }

        // Update the database with the key
        if (type === AvatarType.USER) {
          // Update user with the key
          await updateSelfReq({
            avatarS3Key: key,
            avatarS3Bucket: bucket
          }).catch(error => {
            handleRodaError(error, "Failed to update profile picture");
            setIsLoading(false);

            return;
          });
        } else if (type === AvatarType.COMPANY && (currentCompany?.id || company?.id)) {
          const companyId = currentCompany?.id || company?.id;

          if (!companyId) {
            handleRodaError(new Error("Company ID is missing"), "Failed to update company logo");
            setIsLoading(false);

            return;
          }

          // Update company with the key
          await updateCompanyReq({
            companyId: parseInt(companyId),
            logoS3Key: key,
            logoS3Bucket: bucket
          }).catch(error => {
            handleRodaError(error, "Failed to update company logo");
            setIsLoading(false);

            return;
          });
        }

        refetch();

        if (type === AvatarType.USER) {
          toast.success("Profile picture updated");
        } else {
          toast.success("Company logo updated");
        }

        // Reset file input to allow the same file to be uploaded again
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }

        setIsLoading(false);
      } catch (error) {
        handleRodaError(error, "Failed to upload image");
        setIsLoading(false);
      }
    }
  };

  /**
   * Handle removing an image
   */
  const handleRemoveImage = async () => {
    setIsLoading(true);

    // Update the user to remove the key
    if (type === AvatarType.USER) {
      await updateSelfReq({
        avatarS3Key: null,
        avatarS3Bucket: null
      }).then(() => {
        toast.success("Profile picture removed");
      }).catch(error => {
        handleRodaError(error, "Failed to remove profile picture");
      });
    } else if (type === AvatarType.COMPANY && (currentCompany?.id || company?.id)) {
      const companyId = currentCompany?.id || company?.id;

      if (!companyId) {
        handleRodaError(new Error("Company ID is missing"), "Failed to remove company logo");

        return;
      }

      await updateCompanyReq({
        companyId: parseInt(companyId),
        logoS3Key: null,
        logoS3Bucket: null
      }).then(() => {
        toast.success("Company logo removed");
      }).catch(error => {
        handleRodaError(error, "Failed to remove company logo");
      });
    }

    // Reset file input to allow the same file to be uploaded again
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    refetch();
    setIsLoading(false);
  };

  return (
    <div className="w-full flex flex-col gap-3 items-start">
      <div className="text-brand-cold-metal-900 font-semibold text-lg flex flex-row gap-2 items-center">
        {type === AvatarType.USER && (user?.email && user.id) ? (
          <Avatar
            user={user}
          />
        ) : (
          <CompanyAvatar
            companyName={currentCompany?.name || company?.name || ""}
            size="large"
            logoS3Key={currentCompany?.logoS3Key || company?.logoS3Key || undefined}
          />
        )}

        <p>{type === AvatarType.USER ? "Profile Picture" : "Company Logo"}</p>
      </div>

      <div className="flex gap-2">
        <Button
          title="Upload image"
          iconComponent={(
            <Icon.Upload
              size={16}
              className="text-white"
            />
          )}
          onClick={() => fileInputRef.current?.click()}
          loading={isLoading}
          disabled={isLoading || fetching}
        />

        {hasAvatar && (
          <Button
            title="Remove"
            className="bg-brand-cold-metal-100 text-brand-cold-metal-900 hover:contrast-75"
            onClick={handleRemoveImage}
            disabled={isLoading || fetching}
          />
        )}
      </div>

      <input
        type="file"
        id="avatar-upload"
        ref={fileInputRef}
        accept="image/png, image/jpeg, image/jpg, image/webp, image/heic, image/heif, image/avif"
        className="hidden"
        disabled={isLoading}
        onChange={event => {
          if (event.target.files) {
            handleUploadImage(event.target.files[ 0 ]);
          }
        }}
      />
    </div>
  );
};