import { UserRole } from "@roda/shared/types";

import { Icon } from "~/components/Icon";
import * as SideNav from "~/components/side-navigation/components";
import { useCurrentUser } from "~/contexts/UserContext";

interface HeaderProps {
  onBack?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ onBack }) => {
  const { user } = useCurrentUser();
  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;

  return (
    <header className="flex flex-row gap-3 p-3 items-center border-brand-cold-metal-200 border-solid border-b w-full">
      {isRodaAdmin && (
        <button
          className="font-semibold py-3 px-1 truncate capitalize text-left flex items-center gap-4 hover:bg-brand-cold-metal-100"
          title="Go back"
          aria-label="Go back to admin menu"
          onClick={onBack}
        >
          <Icon.ChevronLeft />
        </button>
      )}

      <SideNav.CompanySwitcher />
    </header>
  );
};