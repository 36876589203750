import { MetricTarget } from "~/fragments/metricTarget";

import type { MetricUpdateGenqlSelection } from "@roda/graphql/genql";

export const MetricUpdate: MetricUpdateGenqlSelection = {
  id: true,
  metricId: true,
  isHealthy: true,
  startDate: true,
  metricTargetId: true,
  metricTarget: MetricTarget,
  notes: true,
  updatedBy: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    jobTitle: true,
    role: true,
    verified: true,
    isMetricOwner: true,
    updatedAt: true,
    createdAt: true,
    avatarS3Key: true,
    avatarS3Bucket: true,
    lastSeenAt: true
  },
  value: true,
  updatedAt: true,
  createdAt: true,
  __typename: true,
  __scalar: true
};
