import { UserRole } from "@roda/shared/types";
import {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import { CompanyAvatar } from "~/components/CompanyAvatar";
import { FlywheelMenu } from "~/components/FlywheelMenu";
import { Icon } from "~/components/Icon";
import { Spacer } from "~/components/Spacer";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";

import * as SideNav from "./components";

type SubMenuLevel = "admin" | "root" | "settings";

export const SideNavigation = () => {
  const { user } = useCurrentUser();

  const {
    currentCompany, setCurrentCompany, isAdmin
  } = useCurrentCompanyContext();

  const {
    flywheel, setActiveFlywheelId, isCheckInDue, userHasMetrics
  } = useSelectedFlywheel();

  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;
  const isMetricOwner = user?.isMetricOwner;
  // In this case, we count a metric owner as someone who owns a metric for the review quarter
  const { hideMainSideNav, setHideMainSideNav } = useSideNavigation();
  const [ subMenuLevel, _setSubMenuLevel ] = useState<SubMenuLevel>(isRodaAdmin && !flywheel ? "admin" : "root");
  // We don't want to animate the first render
  const [ hasChangedSubMenuLevel, setHasChangedSubMenuLevel ] = useState(false);
  const navigate = useNavigate();
  // Permissions to check-in
  const hasAccessToViewCheckIn = isAdmin || isRodaAdmin || isMetricOwner;
  // Check if we're at the start of the quarter

  const setSubMenuLevel: typeof _setSubMenuLevel = level => {
    _setSubMenuLevel(level);
    setHasChangedSubMenuLevel(true);
  };

  useEffect(() => {
    if (isRodaAdmin && subMenuLevel !== "settings") {
      if (currentCompany) {
        setSubMenuLevel("root");
      } else {
        setSubMenuLevel("admin");
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isRodaAdmin, currentCompany ]);

  return (
    <>
      {!hideMainSideNav ? (
        <aside
          className={`w-[275px] sticky top-0 shrink-0 bg-brand-cold-metal-50 border-r-brand-cold-metal-200 border-solid border-r flex flex-col gap-px ${subMenuLevel === "root" ? "[&>*]:slide-in-from-left-4" : "[&>*]:slide-in-from-right-4"} ${!hasChangedSubMenuLevel && "[&>*]:!animate-none"} max-h-[100dvh]`}
          key={subMenuLevel}
        >

          {/** Everyone should see a back button everywhere but the admin & root nav */}
          {subMenuLevel !== "admin" && subMenuLevel !== "root" && (
            <>
              <SideNav.BackButtonHeader
                label={subMenuLevel}
                onClick={() => {
                  if (currentCompany) {
                    navigate(routes.companySettings(currentCompany.id));
                    setSubMenuLevel("root");
                  } else {
                    navigate(routes.home);
                    setSubMenuLevel("root");
                  }
                }}
              />
            </>
          )}

          {(() => {
            switch (subMenuLevel) {
              case "settings": return (
                <>
                  <SideNav.Gap />

                  {!isRodaAdmin && (
                    <>
                      <SideNav.Subheading heading="Account" />

                      <SideNav.NavItem
                        label="Profile"
                        icon={<Icon.Profile className="w-4.5 h-4.5" />}
                        href={routes.account}
                      />

                    </>
                  )}

                  {(isRodaAdmin || isAdmin) && (
                    <>
                      <SideNav.Subheading heading="Organisation" />

                      <SideNav.NavItem
                        label="Company & Billing"
                        icon={<Icon.Company className="w-4.5 h-4.5" />}
                        href={routes.organisationSettings(currentCompany ? currentCompany.id : undefined)}
                      />

                      <SideNav.NavItem
                        label="Members"
                        icon={<Icon.Users className="w-4.5 h-4.5" />}
                        href={routes.members(currentCompany ? currentCompany.id : undefined)}
                      />

                    </>
                  )}

                </>
              );

              case "admin": return (
                <>
                  <SideNav.RodaLogoHeader />

                  <SideNav.Gap />

                  <SideNav.NavItem
                    label="Company Management"
                    icon={<Icon.SettingsCog className="w-4.5 h-4.5" />}
                    onClick={() => setSubMenuLevel("admin")}
                    href={routes.organisationManagement}
                  />

                  <SideNav.Gap />

                  <SideNav.Subheading heading="Account" />

                  <SideNav.NavItem
                    label="Profile"
                    icon={<Icon.Profile className="w-4.5 h-4.5" />}
                    href={routes.account}
                  />

                  <Spacer />

                  <SideNav.Gap />

                  <SideNav.Footer />
                </>
              );

              case "root":
              default: return (
                <>
                  <SideNav.Header
                    onBack={() => {
                      setCurrentCompany(null);
                      setActiveFlywheelId(null);
                      setSubMenuLevel("admin");
                      navigate(routes.organisationManagement);
                    }}
                  />

                  <SideNav.Gap />

                  <FlywheelMenu />

                  {hasAccessToViewCheckIn && (
                    <SideNav.NavItem
                      label="Check-in"
                      icon={<Icon.BarChart className="w-4.5 h-4.5" />}
                      href={routes.checkIn(currentCompany ? currentCompany.id : undefined)}
                      banner={isCheckInDue && userHasMetrics ? { bannerText: "Check-in due" } : undefined}
                    />
                  )}

                  <SideNav.NavItem
                    label="Settings"
                    icon={<Icon.SettingsCog className="w-4.5 h-4.5" />}
                    onClick={() => {
                      setSubMenuLevel("settings");

                      if (user?.role === UserRole.RODA_ADMIN) {
                        navigate(routes.organisationSettings(currentCompany ? currentCompany.id : undefined));
                      } else {
                        navigate(routes.account);
                      }
                    }}
                  />

                  <Spacer />

                  {(isRodaAdmin || isAdmin) && (
                    <SideNav.NavItem
                      label="Invite team members"
                      icon={<Icon.InviteUsers className="w-4.5 h-4.5" />}
                      href={routes.members(currentCompany ? currentCompany.id : undefined)}
                    />
                  )}

                  <SideNav.NavItem
                    label="Getting started"
                    icon={<Icon.HelpCircle className="w-4.5 h-4.5" />}
                    href={routes.gettingStarted}
                    onClick={() => setHideMainSideNav(true)}
                  />

                  <SideNav.Gap />

                  <SideNav.Footer />
                </>
              );
            }
          })()}
        </aside>
      ) : (
        <>
          <div className={`items-center p-3 w-[55px] shrink-0 bg-brand-cold-metal-50 border-r-brand-cold-metal-200 border-solid border-r flex flex-col gap-px ${subMenuLevel === "root" ? "[&>*]:slide-in-from-left-4" : "[&>*]:slide-in-from-right-4"} ${!hasChangedSubMenuLevel && "[&>*]:!animate-none"} max-h-[100dvh]`}>
            <div
              className="cursor-pointer"
              onClick={() => {
                setHideMainSideNav(false); // Show the main nav bar
                navigate(routes.home); // Navigate user back to their home page
              }}
            >
              <CompanyAvatar
                companyName={currentCompany ? currentCompany.name : ""}
                logoS3Key={currentCompany?.logoS3Key ?? undefined}
              />
            </div>

          </div>

          <div className="absolute left-12 top-4 pl-4 z-20">
            <Button
              title="Exit"
              iconComponent={<Icon.ChevronLeft />}
              className="bg-brand-cold-metal-200 text-brand-cold-metal-800 font-normal px-2 py-1 text-xs lg:px-2"
              onClick={() => {
                navigate(routes.home); // Navigate user back to their home page
                setActiveFlywheelId(null);
                setHideMainSideNav(false);
              }}
            />
          </div>

        </>

      )}

    </>
  );
};
