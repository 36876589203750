import { ReadOnlyUser } from "~/fragments/user";

import type { SubgoalGenqlSelection } from "@roda/graphql/genql";

export const Subgoal: SubgoalGenqlSelection = {
  id: true,
  endDate: true,
  startDate: true,
  goal: true,
  flywheelGoalId: true,
  latestUpdate: {
    id: true,
    flywheelGoalId: true,
    startDate: true,
    value: true,
    notes: true,
    isHealthy: true,
    updatedAt: true,
    updatedBy: ReadOnlyUser
  },
  updates: {
    id: true,
    flywheelGoalId: true,
    startDate: true,
    value: true,
    notes: true,
    isHealthy: true,
    updatedAt: true,
    updatedBy: ReadOnlyUser
  },
  isHealthy: true,
  isCheckInDue: true,
  latestProgress: true
};
