import {
  UserCompanyRole,
  UserRole
} from "@roda/shared/types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";

import { STORAGE_KEYS } from "~/constants/storageKeys";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { ListCompaniesResponse } from "~/hooks/company";
import { useListCompanies } from "~/hooks/company";
import {
  useGetCompany,
  type GetCompanyResponse
} from "~/hooks/company/use-get-company";
import type { ListUsersType } from "~/hooks/user/use-list-users";
import { useListUsers } from "~/hooks/user/use-list-users";

import type { PropsWithChildren } from "react";
import type {
  CombinedError,
  RequestPolicy
} from "urql";

interface CurrentCompanyContextProps {
  loading: boolean;
  companies: ListCompaniesResponse | null;
  currentCompany?: GetCompanyResponse | null;
  refetchCurrentCompany: () => void;
  setCurrentCompany: (company: GetCompanyResponse | null) => void;
  refetchCompanies: () => void;
  isAdmin: boolean;
  fetchingUsers: boolean;
  fetchUsersError?: CombinedError | null;
  companyUsers: ListUsersType;
  refetchCompanyUsers: ({ requestPolicy }: { requestPolicy?: RequestPolicy }) => void;
}

const CurrentCompanyContext = createContext<CurrentCompanyContextProps>({
  loading: false,
  companies: null,
  companyUsers: [],
  currentCompany: null,
  refetchCompanyUsers: () => null,
  setCurrentCompany: () => null,
  refetchCompanies: () => null,
  refetchCurrentCompany: () => null,
  isAdmin: false,
  fetchingUsers: false,
  fetchUsersError: null
});

export const useCurrentCompanyContext = () => useContext(CurrentCompanyContext);

export const CurrentCompanyProvider = (props: PropsWithChildren) => {
  const { user, fetching: userFetching } = useCurrentUser();
  const { authenticated } = useAuth();
  const location = useLocation();
  const [ currentCompany, setCurrentCompany ] = useLocalStorage<GetCompanyResponse | null>(STORAGE_KEYS.LOCAL_STORAGE.CURRENT_COMPANY, null);

  const [
    {
      data: users, fetching: fetchingUsers, error: fetchUsersError
    },
    refetchCompanyUsers
  ] = useListUsers(currentCompany ? +currentCompany.id : undefined);

  const [ currentCompanyUsers, setCurrentCompanyUsers ] = useLocalStorage<ListUsersType>(STORAGE_KEYS.LOCAL_STORAGE.CURRENT_COMPANY_USERS, []);
  const [ { data: currentCompanyData }, refetchCurrentCompany ] = useGetCompany(Number(currentCompany?.id), { pause: true });

  // Extract company ID from URL path
  const getCompanyIdFromPath = useCallback((path: string) => {
    const match = path.match(/\/organisations\/(\d+)/);

    return match ? parseInt(match[ 1 ], 10) : undefined;
  }, []);

  // Fetch all of the companies - but only for Roda admins
  const [ { data, fetching }, refetchCompanies ] = useListCompanies({
    pause: !authenticated || !user || user.role !== UserRole.RODA_ADMIN,
    requestPolicy: "network-only"
  });

  const userCompanies = user?.userCompanies;
  const rodaAdminCompanies = data?.listCompanies;
  const isAdmin = user?.role === UserRole.RODA_ADMIN || currentCompany?.userCompany?.role === UserCompanyRole.ADMIN;

  useEffect(() => {
    if (!currentCompany) {
      setCurrentCompanyUsers([]);

      return;
    }

    if (users?.listUsers) {
      setCurrentCompanyUsers(users?.listUsers || []);
    }
  }, [
    users?.listUsers,
    setCurrentCompanyUsers,
    currentCompany
  ]);

  useEffect(() => {
    // As long as we aren't a Roda Admin, we'll just set the first company as the current company, if there isn't one already set

    if (user?.role !== UserRole.RODA_ADMIN && userCompanies?.length) {
      if (location.pathname.includes("new")) {
        setCurrentCompany(null);
      } else {
        const userCompaniesDoesNotIncludeCurrentCompany = userCompanies?.every(company => company.id !== currentCompany?.id);

        if ((!currentCompany || userCompaniesDoesNotIncludeCurrentCompany) && !userFetching && !location.pathname.includes("onboarding")) {
          if (userCompanies[ 0 ]) {
            setCurrentCompany(userCompanies[ 0 ] as GetCompanyResponse);
          } else {
            // If there are no companies, set the current company to null
            setCurrentCompany(null);
            window.location.href = "/onboarding/new";
          }
        }
      }
    }

    const companyIdFromPath = getCompanyIdFromPath(location.pathname);
    const currentCompanyId = typeof currentCompany?.id === "string" ? parseInt(currentCompany.id, 10) : currentCompany?.id;

    if (companyIdFromPath && currentCompanyId !== companyIdFromPath) {
      const company = userCompanies?.find(company => {
        const companyId = typeof company.id === "string" ? parseInt(company.id, 10) : company.id;

        return companyId === companyIdFromPath;
      });

      if (company) {
        setCurrentCompany(company as GetCompanyResponse);
      }

      return;
    }

    // If we're not a Roda admin, user doesn't belong to any companies and we're not currently onboarding,
    if (!!user && user?.role !== UserRole.RODA_ADMIN && !userCompanies?.length && !location.pathname.includes("onboarding")) {
      // We should make sure the user is redirected to the right place
      setCurrentCompany(null);
      window.location.href = "/onboarding/new";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.role,
    userCompanies,
    location.pathname
  ]);

  useEffect(() => {
    if (currentCompanyData?.getCompany?.id) {
      // keep the current company in sync with the data from the getCompany hook
      setCurrentCompany(currentCompanyData.getCompany);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ currentCompanyData?.getCompany ]);

  const handleRefetchCompanies = useCallback(() => {
    if (user?.role === UserRole.RODA_ADMIN) {
      refetchCompanies({ requestPolicy: "cache-and-network" });
    }
  }, [ refetchCompanies, user ]);

  const selectCompany = useCallback((company: GetCompanyResponse | null) => {
    setCurrentCompany(company);
  }, [ setCurrentCompany ]);

  const handleRefetchCurrentCompany = useCallback(() => {
    if (currentCompany) {
      refetchCurrentCompany({ requestPolicy: "network-only" });
    }
  }, [ refetchCurrentCompany, currentCompany ]);

  const memoedValue = useMemo<CurrentCompanyContextProps>(
    () => ({
      loading: fetching,
      companies: user?.role === UserRole.RODA_ADMIN ? rodaAdminCompanies || [] : userCompanies || [],
      currentCompany,
      setCurrentCompany: selectCompany,
      refetchCompanies: handleRefetchCompanies,
      refetchCurrentCompany: handleRefetchCurrentCompany,
      isAdmin,
      fetchingUsers,
      fetchUsersError,
      companyUsers: currentCompanyUsers || [],
      refetchCompanyUsers
    }),
    [
      fetching,
      user?.role,
      rodaAdminCompanies,
      fetchUsersError,
      userCompanies,
      refetchCompanyUsers,
      currentCompany,
      fetchingUsers,
      handleRefetchCurrentCompany,
      selectCompany,
      handleRefetchCompanies,
      isAdmin,
      currentCompanyUsers
    ]
  );

  return (
    <CurrentCompanyContext.Provider value={memoedValue}>
      {props.children}
    </CurrentCompanyContext.Provider>
  );
};