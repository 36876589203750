import { zodResolver } from "@hookform/resolvers/zod";
import { enumUserCompanyRole } from "@roda/graphql/genql";
import { UserRole } from "@roda/shared/types";
import {
  useEffect,
  useMemo
} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Avatar } from "~/components/Avatar";
import { Breadcrumbs } from "~/components/Breadcrumbs";
import { Button } from "~/components/Button";
import { Divider } from "~/components/Divider";
import { InviteMemberSchema } from "~/components/form/formSchemas";
import { Input } from "~/components/form/Input";
import { SelectInput } from "~/components/form/SelectInput";
import { Icon } from "~/components/Icon";
import { Loading } from "~/components/Spinner";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSideNavigation } from "~/contexts/SideNavigationContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useError } from "~/hooks/useError";
import { useIsMobile } from "~/hooks/useIsMobile";
import { useInviteUser } from "~/hooks/user/use-invite-user";
import { truncateEmail } from "~/utils/truncateEmail";

import type { User as UserType } from "@roda/graphql/genql";

export const roleLabels = {
  [ enumUserCompanyRole.ADMIN ]: "Admin",
  [ enumUserCompanyRole.USER ]: "User"
};

export type RoleLabelKey = keyof typeof roleLabels;

export const Members = () => {
  const { user: currentUser } = useCurrentUser();
  const [ { fetching: sendingInvite }, inviteUser ] = useInviteUser();

  const {
    currentCompany, refetchCompanies, fetchingUsers, fetchUsersError, companyUsers, refetchCompanyUsers
  } = useCurrentCompanyContext();

  const { setHideMainSideNav } = useSideNavigation();

  // On render of this page - always show the main side nav
  useEffect(() => {
    setHideMainSideNav(false);
  }, [ setHideMainSideNav ]);

  const { handleRodaError, assertGraphQLSuccess } = useError();
  const companyId = currentCompany?.id;
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const {
    handleSubmit, register, reset, getValues, setValue, clearErrors, formState: { errors }
  } = useForm({
    resolver: zodResolver(InviteMemberSchema),
    shouldFocusError: false
  });

  useEffect(() => {
    if (fetchUsersError) {
      handleRodaError(fetchUsersError, "Failed to fetch users");
    }
  }, [ fetchUsersError, handleRodaError ]);

  const handleUserClick = (user: UserType) => {
    // if you tap on yourself navigate to your own profile
    if (user.id === currentUser?.id) {
      navigate(routes.account);

      return;
    }

    // navigate to edit member
    navigate(`${currentCompany ? "/organisations/" : ""}${routes.editMember(user.id, currentCompany ? currentCompany.id : undefined)}`, {
      state: {
        userData: {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role,
          id: user.id,
          jobTitle: user.jobTitle,
          verified: user.verified,
          avatarS3Key: user.avatarS3Key
        }
      }
    });
  };

  const submitHandler = handleSubmit(async formData => {
    if (currentUser?.role !== UserRole.RODA_ADMIN && !companyId) {
      toast.error("You must be part of an organisation to invite a user");

      return;
    }

    try {
      const response = await inviteUser({
        companyId: currentCompany?.id ? +currentCompany.id : +companyId!,
        email: formData.email,
        role: formData.role
      });

      assertGraphQLSuccess(response);

      if (response.data) {
        toast.success("Invite sent");
        reset();
        refetchCompanyUsers({ requestPolicy: "network-only" });
        refetchCompanies();
      }
    } catch (error) {
      console.error("Error inviting user:", error);

      if (error instanceof Error) {
        handleRodaError(error, error.message);
      } else {
        handleRodaError(error, "Something went wrong - please contact Support.");
      }
    }
  });

  const sortedUsers = useMemo(() => {
    return companyUsers?.sort((a, b) => {
      // Check if firstName of either user is undefined, and handle accordingly
      if (!a.firstName && !b.firstName) {
        return 0; // Both are undefined, so we consider them equal in terms of sorting
      } else if (!a.firstName) {
        return 1; // Only a's firstName is undefined, so a should come after b
      } else if (!b.firstName) {
        return -1; // Only b's firstName is undefined, so b should come after a
      } else {
        return a.firstName.localeCompare(b.firstName);
      }
    }) ?? [];
  }, [ companyUsers ]);

  return (
    <div className={`flex flex-col w-full flex-1 bg-white ${!isMobile && "px-10"}`}>
      {/* Breadcrumbs */}
      {!isMobile && (
        <Breadcrumbs
          crumbs={[ { label: "Members" } ]}
        />
      )}

      <div className="flex flex-col items-center justify-start w-full flex-1">

        {/* Container for form */}
        <div className={`max-w-[900px] flex-1 w-full text-xs sm:text-sm flex flex-col px-5 ${isMobile ? "mt-4 gap-4" : "mt-10 gap-8"}`}>

          {/* Header */}
          <div className="flex flex-col w-full">
            <div className={`font-bold text-left pb-2 ${isMobile ? "text-lg" : "text-2xl"}`}>Members</div>

            <p className={`text-brand-sub-text ${isMobile ? "text-md" : "text-lg"}`}>Manage team members and set their access level</p>
          </div>

          <Divider />

          <form
            onSubmit={submitHandler}
            className="flex flex-col"
          >
            <div className={`flex flex-wrap gap-4 ${isMobile ? "flex-col w-full" : "flex-row items-end"}`}>

              <div className="flex-1">
                <Input
                  label="Invite team member"
                  {...register("email")}
                  hasError={!!errors.email}
                  errorMessage={errors.email?.message as string}
                  inputMode="email"
                />
              </div>

              <SelectInput
                {...register("role")}
                options={[ enumUserCompanyRole.ADMIN, enumUserCompanyRole.USER ]}
                label="Invite as"
                placeholder="Select a role"
                onValueChange={value => setValue("role", value)}
                clearFormError={() => clearErrors("role")}
                hasError={!!errors.role}
                errorMessage={errors.role?.message as string}
                value={getValues("role")}
                renderOptionLabel={option => roleLabels[ option as RoleLabelKey ]}
                className={`${isMobile ? "w-full" : "max-w-[150px] min-w-[150px]"}`}
              />

              <Button
                className={`${isMobile && "w-full"}`}
                loading={sendingInvite}
                disabled={sendingInvite}
                title="Send invite"
                type="submit"
                iconComponent={(
                  <Icon.Send />
                )}
              />
            </div>
          </form>

          <Divider />

          {/* User List */}
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <p>Name</p>

              <p>Access Level</p>
            </div>

            <Divider />

            <div className="flex flex-col gap-4 w-full overflow-auto">
              {(companyUsers || !fetchingUsers) && (
                <>
                  {sortedUsers?.length ? (
                    <ul className="text-sm space-y-3 overflow-hidden">
                      {sortedUsers.map(user => {
                        return (
                          <li
                            key={user.id}
                            className={`flex gap-4 items-center whitespace-nowrap cursor-pointer hover:bg-brand-cold-metal-100 rounded-lg p-2 justify-between ${!user.verified ? "opacity-50" : "opacity-100"}`}
                            onClick={() => handleUserClick(user as UserType)}
                          >
                            <div className="flex items-center overflow-hidden gap-x-1">
                              <Avatar
                                user={{
                                  firstName: user.firstName,
                                  lastName: user.lastName,
                                  email: user.email,
                                  avatarS3Key: user.avatarS3Key
                                }}
                                px={28}
                              />

                              {user.firstName && user.lastName && (
                                <p className="flex-1 break-all truncate">

                                  {`${user.firstName} ${user.lastName}
                                  ${user.id === currentUser?.id ? " (you)" : ""}`}
                                </p>
                              )}

                              <span className="text-brand-sub-text flex-1 break-all">{`${user.firstName && user.lastName ? truncateEmail(user.email) : user.email}${!user.verified ? " (invited)" : ""}`}</span>
                            </div>

                            <div className="flex w-auto px-1.5 py-1 capitalize bg-brand-cold-metal-100 rounded-full text-center text-xs">
                              {user?.userCompany?.role.toLowerCase()}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    null
                  )}

                </>
              )}
            </div>
          </div>

          {(fetchingUsers) && <Loading.AbsoluteCenter containerProps={{ className: "my-8" }} />}
        </div>
      </div>
    </div>
  );
};
