import { UserRole } from "@roda/shared/types";
import { type FlywheelTemplateUnitTypeLabelEnum } from "@roda/shared/types";
import { useMemo } from "react";

import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { Icon } from "~/components/Icon";
import { MetricCardWrapper } from "~/components/metric/MetricCardWrapper";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import type { FlywheelReviewMetricData } from "~/hooks/flywheel/use-get-flywheel-review";
import { parseDbDate } from "~/utils/dates/parseDbDate";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getMetricSvgIcon } from "~/utils/getMetricSvgIcon";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getUserDisplayName } from "~/utils/getUserDisplayName";
import { getWeekMetricTarget } from "~/utils/getWeekMetricTarget";

interface ReviewMetricCardProps {
  metric: FlywheelReviewMetricData;
  onViewClick: (metric: FlywheelReviewMetricData) => void;
  onTargetChangeClick: (metric: FlywheelReviewMetricData) => void;
}
export const ReviewMetricCard: React.FC<ReviewMetricCardProps> = ({
  metric, onViewClick, onTargetChangeClick
}) => {
  const { user } = useCurrentUser();
  // Permissions checks
  const { isAdmin } = useCurrentCompanyContext();
  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;

  // Review is due if the metric is flagged for review AND the user hasn't already reviewed it
  const isReviewDue = useMemo(() => metric?.isReviewDue && !metric.reviewers?.find(reviewer => reviewer.userId === user?.id), [
    metric?.isReviewDue,
    metric?.reviewers,
    user?.id
  ]);

  const { flywheel, subgoalInReview } = useSelectedFlywheel();
  // Review data
  // Find the target that covers the quarter currently being reviewed
  const reviewTarget = useMemo(() => subgoalInReview ? getWeekMetricTarget(metric.targets, dayjs(subgoalInReview.startDate)) : undefined, [ metric.targets, subgoalInReview ]);
  // Metric is disabled if its toDate is before the start of the quarter we're reviewing
  const isDisabled = useMemo(() => metric?.toDate && dayjs(metric?.toDate).isBefore(subgoalInReview?.startDate), [ metric?.toDate, subgoalInReview?.startDate ]);
  // Metric is new for current quarter if its fromDate is the same as the subgoal startDate
  const isNewForReviewQuarter = useMemo(() => metric?.fromDate && dayjs(metric.fromDate).isSame(subgoalInReview?.startDate, "day"), [ metric.fromDate, subgoalInReview?.startDate ]);
  // Take the latest review - they've been ordered by date so this will be the last one
  const latestUserReview = useMemo(() => metric.reviewers?.length ? metric.reviewers[ metric.reviewers.length - 1 ] : undefined, [ metric.reviewers ]);
  // TODO - this is a temporary check to see if an admin has reviewed the metric
  const adminHasReviewed = !!metric.reviewers?.length;

  /** Format the text for the value & unit we show on each metric review card */
  const reviewTargetText = useMemo(() => {
    const symbol = getUnitSymbol(metric.unitTypeLabel, flywheel?.currency);

    return formatGoalNumber(Number(reviewTarget?.target), symbol, {
      stripTrailingZeros: true,
      shouldCompact: true
    });
  }, [
    flywheel?.currency,
    metric.unitTypeLabel,
    reviewTarget?.target
  ]);

  return (
    <MetricCardWrapper
      bgColour={isReviewDue ? "#FAFCFF" : undefined}
      borderColour={isReviewDue ? "#D1E0FF" : isDisabled ? "#F3F4F6" : undefined}
    >
      <div className={`flex flex-row items-center gap-x-3 flex-1 *:min-w-0  ${isDisabled ? "opacity-60" : ""}`}>
        <div className={` border-[1px] p-2 rounded-lg ${isReviewDue ? "text-brand-check-in-due-800 border-brand-check-in-due-100" : "border-brand-cold-metal-200"} `}>
          {getMetricSvgIcon(metric.unitTypeLabel as FlywheelTemplateUnitTypeLabelEnum)}

        </div>

        <div className="flex flex-col items-start text-sm overflow-hidden flex-1">
          <div className="flex flex-row gap-2 items-center w-full">
            <p
              className={`font-semibold truncate ${isReviewDue ? "text-brand-check-in-due-900" : " text-brand-cold-metal-800"}`}
            >
              {metric.name}
            </p>

            {isNewForReviewQuarter && (
              <div className="bg-brand-healthy-green-500 rounded-md text-xs px-2 py-1 text-brand-cold-metal-50">
                <p>New</p>
              </div>
            )}

          </div>

          {latestUserReview && (
            <p className={`text-xs break-normal ${isReviewDue ? "text-brand-check-in-due-300" : "text-brand-cold-metal-400"}`}>{`${isDisabled ? "Disabled" : isNewForReviewQuarter ? "Created" : "Updated"} ${parseDbDate(latestUserReview.updatedAt).fromNow()} by `}<span className="whitespace-nowrap">{getUserDisplayName(latestUserReview.user)}</span></p>
          )}

        </div>
      </div>

      <div className="pt-3 sm:pt-0 w-full md:w-auto justify-between flex flex-col md:flex-row gap-2 flex-1 pl-12 sm:pl-2">
        {!isDisabled && (
          <div className={`flex flex-col justify-center gap-1 ${isDisabled ? "opacity-60" : ""}`}>
            {!isNewForReviewQuarter && !isDisabled && (
              <div>
                {!isReviewDue && reviewTarget ? (
                  <div className="flex flex-row gap-1 items-center">
                    <p className="text-xs text-brand-cold-metal-400">Target set by</p>

                    <Avatar
                      user={reviewTarget.createdBy}
                      px={16}
                    />
                  </div>
                ) : (
                  <div className="flex flex-row gap-2 items-center text-xs text-brand-check-in-due-300">
                    <p>Awaiting review</p>

                    <Icon.Clock />
                  </div>
                )}
              </div>
            )}

            <div className="gap-2 flex flex-row flex-wrap md:max-w-[120px] xl:max-w-[140px] 2xl:max-w-none *:min-w-0">

              <p className="text-sm md:text-xs font-semibold mt-2 md:mt-0">{reviewTargetText}</p>

              <p className="text-sm md:text-xs text-brand-cold-metal-400 mt-2 md:mt-0">{metric.unitName}</p>
            </div>

            <p className={`text-xs truncate ${isReviewDue ? "text-brand-check-in-due-300" : "text-brand-cold-metal-400"}`}>{metric.unitDisplay === "weekly" ? "Weekly target" : "Quarterly target"}</p>

          </div>
        )}

        <div className="flex flex-row gap-2 items-center flex-1 justify-end md:flex-col xl:flex-row">
          <Button
            title="View"
            className={`${isReviewDue ? " bg-brand-check-in-due-50" : "bg-brand-cold-metal-100"} text-brand-cold-metal-800 p-2 sm:px-4 flex-1 md:flex-auto md:max-w-[60px] min-w-[120px] lg:max-w-[120px] max-h-[32px] min-h-[32px]`}
            onClick={() => onViewClick(metric)}
          />

          {!isDisabled && !(adminHasReviewed && !isAdmin && !isRodaAdmin) && (
            <Button
              title={latestUserReview ? "Change target" : "Set target"}
              className={`${latestUserReview ? "bg-brand-cold-metal-900" : "bg-brand-check-in-due-800"} p-2 sm:px-4 flex-1 lg:flex-auto md:max-w-[120px] min-w-[120px] max-h-[32px] min-h-[32px]`}
              onClick={() => onTargetChangeClick(metric)}
            />
          )}

        </div>
      </div>

    </MetricCardWrapper>
  );
};