import { Company } from "~/fragments/company";

import type { UserGenqlSelection } from "@roda/graphql/genql";

export const ReadOnlyUser: UserGenqlSelection = {
  id: true,
  firstName: true,
  lastName: true,
  status: true,
  email: true,
  verified: true,
  role: true,
  avatarS3Key: true
};

export const User: UserGenqlSelection = {
  id: true,
  firstName: true,
  lastName: true,
  email: true,
  jobTitle: true,
  role: true,
  verified: true,
  userCompanies: { ...Company },
  isMetricOwner: true,
  updatedAt: true,
  createdAt: true,
  avatarS3Key: true,
  avatarS3Bucket: true,
  lastSeenAt: true
};
