import {
  useState,
  useCallback
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { routes } from "~/constants/routes";

interface UseRequestMagicLinkOptions {
  onLinkSent?: (email: string) => void;
}

/**
 * Hook to request a magic link for authentication.
 *
 * @param {Function} onLinkSent - Callback that's triggered once the link is sent.
 *  Takes a users email as an argument.
 * @returns {Object} Contains the requestLink function and a loading state.
 */
export const useRequestMagicLink = ({ onLinkSent }: UseRequestMagicLinkOptions = {}) => {
  const [ loading, setIsLoading ] = useState(false);
  const navigate = useNavigate();

  // Sends a request to the backend to send a magic link to the user's email.
  const requestLink = useCallback(async (emailValue: string) => {
    setIsLoading(true);
    const loadingToast = toast.loading("Loading...", { duration: Infinity });

    try {
      const response = await fetch(import.meta.env.VITE_BASE_URL + `/auth/link/authorize?email=${encodeURIComponent(emailValue)}`);

      if (response.ok) {
        // navigate user to dashboard
      } else {
        // User was not found, so we need to onboard the user!

        navigate(routes.onboarding({ newCompany: true }));
      }
    } catch (error) {
      toast.error(error as string);
    } finally {
      setIsLoading(false);
      toast.dismiss(loadingToast);

      if (onLinkSent) {
        onLinkSent(emailValue);
      }
    }
  }, [ navigate, onLinkSent ]);

  return {
    requestLink,
    loading
  };
};
