import { Icon } from "~/components/Icon";
import type { OnClickRequiredProps } from "~/components/side-navigation/components/NavItem";
import { useIsMobile } from "~/hooks/useIsMobile";

interface ExpandedNavItemOptionProps {
  label: string;
  isSelected: boolean;
  hidden?: boolean;
  icon?: React.ReactNode;
  iconMode?: "edit" | "delete";
  editAction?: () => void;
}

type ExpandedNavItemOptionType = ExpandedNavItemOptionProps & OnClickRequiredProps;

export const ExpandedNavItemOption: React.FC<ExpandedNavItemOptionType> = ({
  label, onClick, isSelected, icon, iconMode, editAction, hidden
}) => {
  const isMobile = useIsMobile();

  if (hidden) {
    return <></>;
  }

  return (
    <div
      className={`${isMobile ? "pl-1" : "pl-2"} group pr-2 mx-2 justify-between cursor-pointer text-xs text-start flex rounded-lg relative `}
    >
      <div
        onClick={() => onClick?.()}
        className={` ${isSelected ? "bg-brand-cold-metal-100 text-brand-cold-metal-700" : "text-brand-cold-metal-600"} flex flex-row justify-between items-center w-full max-w-[250px] group hover:bg-brand-cold-metal-200 py-1.5 px-2.5 transition-hover duration-300 rounded-md`}
      >
        <div
          className="flex flex-row gap-x-1 items-center w-[90%]"
        >
          {icon && <span>{icon}</span>}

          <span className="flex-1 break-words w-full">{label}</span>
        </div>

      </div>

      {editAction && (
        <div
          className="absolute right-3 top-1/2 p-2 -translate-y-1/2"
          onClick={editAction}
        >
          {Icon[ iconMode === "delete" ? "Bin" : "SettingsCog" ]({ className: `${isSelected ? "opacity-100" : "opacity-0"} ${iconMode === "delete" ? "right-3.5" : "right-3"} absolute  top-1/2 -translate-y-1/2  text-brand-cold-metal-400 group-hover:opacity-100 hover:text-brand-cold-metal-800 transition-opacity duration-300` })}
        </div>
      )}

    </div>
  );
};