import clsx from "clsx";
import {
  useEffect,
  useState
} from "react";
import {
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

import { Icon } from "~/components/Icon";
import { MetricCard } from "~/components/metric/MetricCard";
import { NoWrap } from "~/components/NoWrap";
import { PillMessage } from "~/components/PillMessage";
import { SaveImageButton } from "~/components/SaveImageButton";
import { Spacer } from "~/components/Spacer";
import { EditStepMenu } from "~/components/step/EditStepMenu";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { CustomiseMetricStage } from "~/contexts/CustomiseMetricContext/metric-reducer";
import { ImageCaptureTarget } from "~/contexts/ImageCaptureContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useIsMobile } from "~/hooks/useIsMobile";
import dayjs from "~/utils/dayjs";
import { getWeekNumber } from "~/utils/getWeekNumber";

import type {
  Flywheel as FlywheelType,
  Step as StepType
} from "@roda/graphql/genql";

const getStepFromFlywheel = (flywheel: FlywheelType, stepId: StepType["id"]) => {
  return flywheel?.steps?.find(step => step.id === stepId);
};

export const Step = () => {
  const params = useParams() as { stepId: StepType["id"], companyId?: string };
  const { state: locationState } = useLocation();
  const { step: stepLocationState } = (locationState ?? {}) as { step?: StepType };
  const dispatch = useCustomiseMetricDispatch();

  const {
    flywheel: flywheelCtx, flywheelStartWeek, flywheelCycleNotStarted, flywheelSubgoals
  } = useSelectedFlywheel();

  const step = stepLocationState ?? flywheelCtx ? getStepFromFlywheel(flywheelCtx!, params.stepId) : null;
  const currentQuarterIdx = flywheelSubgoals?.findIndex(s => dayjs().subtract(1, "week").startOf("isoWeek").isBetween(s.startDate, s.endDate, "days", "[]"));
  const { currentCompany } = useCurrentCompanyContext();
  const isMobile = useIsMobile();
  const [ isCheckInOpen, setIsCheckInOpen ] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [ params.stepId ]);

  if (!step) return null;

  return (
    <ImageCaptureTarget captureId="step-all">
      <div
        className="flex flex-col h-full md:py-10"
      >
        {!isMobile && (
          <div className="flex items-center gap-4">
            <div className="flex flex-row items-center gap-2">

              <p className="text-2xl font-semibold text-pretty">
                {step.alias || step.name}
              </p>

              <EditStepMenu
                step={step}
              />
            </div>

            <Spacer />

            {currentQuarterIdx !== -1 && (
              <p className="text-base text-body-light">
                {`Q${currentQuarterIdx ? currentQuarterIdx + 1 : 1} - Week ${getWeekNumber({
                  // Set this to show the week number for last week - the week we can submit updates for
                  date: dayjs().subtract(1, "week").startOf("isoWeek"),
                  flywheelStartWeek
                })}`}

                {" "}

                <NoWrap>
                  {`(${dayjs().subtract(1, "week").startOf("isoWeek").format("MMMM DD")})`}
                </NoWrap>
              </p>
            )}

            <SaveImageButton
              captureId="step-all"
              html2canvasOptions={{
                onclone(_, element) {
                  // add some padding to the otherwise padding-less container
                  // (!) this doesn't give the ChartJS a chance to resize
                  // properly, therefore pushes it (!!!), so minimise padding where possible (argh!)
                  element.style.padding = "10px 10px 0 10px";
                },
                allowTaint: true,
                useCORS: true
              }}
              className="mobile:hidden shrink-0"
            />
          </div>
        )}

        <div
          className="grid max-md:grid-cols-1 md:[grid-template-columns:repeat(2,1fr)] gap-8 justify-items-start mt-10 md:mt-4"
        >

          {!step.metrics?.length && (
            <div className="w-full flex flex-col">
              <PillMessage
                text="This step doesn't have any metrics"
                icon={<Icon.InfoCircle />}
              />

              <div
                className={clsx("border-[1.5px] border-solid mt-2 border-blue-200 hover:border-blue-400 flex flex-row flex-center rounded-xl py-4 min-h-[250px] max-h-[400px] flex-wrap text-left  bg-blue-50 text-blue-300 hover:text-blue-500 transition-colors text-lg font-semibold no-underline select-none cursor-pointer")}
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_METRIC",
                    stage: CustomiseMetricStage.SELECT_OR_CREATE,
                    step: {
                      id: step.id,
                      name: step.name
                    },
                    metric: undefined
                  });
                  navigate(routes.addMetric(step.id, params.companyId));
                }}
              >

                Add metric

              </div>
            </div>
          )}

          {step.metrics?.filter(metric => !metric.toDate || dayjs(metric.toDate).isAfter(dayjs())).map(metric => {
            const isCheckInDue = !flywheelCycleNotStarted && metric.isCheckInDue;

            return (
              <ImageCaptureTarget
                key={metric.id}
                captureId={`step-${metric.id}`}
              >
                <div
                  key={metric.id}
                  onClick={() => {
                    if (!isCheckInOpen) {
                      navigate(routes.stepMetric(params.stepId, metric.id, currentCompany ? currentCompany.id : undefined), { state: { metric } });
                    }
                  }}
                  className={clsx(`border-[1.5px] border-solid ${isCheckInDue ? "border-brand-check-in-due-100 hover:border-blue-500 ring-blue-500" : "border-brand-cold-metal-200 hover:border-brand-cold-metal-200 ring-brand-cold-metal-200"} flex flex-col rounded-xl min-w-0 min-h-[250px] max-h-[400px] w-full hover:ring-1 flex-wrap text-left cursor-pointer`, isCheckInDue ? "bg-[#FAFCFF]" : "bg-white")}
                >
                  <MetricCard
                    metric={metric}
                    onCheckInChange={isOpen => setIsCheckInOpen(isOpen)}
                  />
                </div>
              </ImageCaptureTarget>
            );
          })}

          {(isMobile && !!step.metrics?.length) && (
            <div className="w-full flex flex-col">
              <div
                className={clsx("border-[1.5px] border-solid mt-2 border-blue-200 hover:border-blue-400 flex flex-row flex-center rounded-xl py-4  flex-wrap text-left  bg-blue-50 text-blue-300 hover:text-blue-500 transition-colors text-lg font-semibold no-underline select-none cursor-pointer")}
                onClick={() => {
                  dispatch({
                    type: "SET_SELECTED_METRIC",
                    stage: CustomiseMetricStage.SELECT_OR_CREATE,
                    step: {
                      id: step.id,
                      name: step.name
                    },
                    metric: undefined
                  });
                  navigate(routes.addMetric(step.id, params.companyId));
                }}
              >

                Add metric

              </div>
            </div>
          )}
        </div>
      </div>
    </ImageCaptureTarget>
  );
};
