import { useTypedQuery } from "@roda/graphql/urql";
import { type Stripe } from "stripe";

import { Company } from "~/fragments/company";
import { ReducedFlywheel } from "~/fragments/flywheel";
import {
  ReadOnlyUser,
  User
} from "~/fragments/user";

export type SessionResponse = ReturnType<typeof useGetSession>[0]["data"];
export type UserCompanies = NonNullable<SessionResponse>["session"]["userCompanies"];
export type UserCompany = NonNullable<UserCompanies>[number] & {stripeStatus: Stripe.Subscription["status"] | null};
export type UserSession = NonNullable<SessionResponse>["session"];
export type ReducedCompanyFlywheelType = NonNullable<UserCompany>["flywheels"];

export const useGetSession = (authenticated: boolean) => {
  return useTypedQuery({
    requestPolicy: "network-only",
    pause: !authenticated,
    query: {
      session: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        jobTitle: true,
        role: true,
        verified: true,
        isMetricOwner: true,
        updatedAt: true,
        createdAt: true,
        avatarS3Key: true,
        avatarS3Bucket: true,
        lastSeenAt: true,
        fakeNowDate: true,
        userFlywheels: { ...ReducedFlywheel },
        userCompanies: {
          ...Company,
          userCompany: { role: true }
        }
      }
    }
  });
};