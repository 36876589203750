import { UserRole } from "@roda/shared/types";
import {
  Navigate,
  Outlet,
  Route,
  Routes
} from "react-router-dom";

import { FlywheelContainer } from "~/components/FlywheelContainer";
import { Onboarding } from "~/components/onboarding/Onboarding";
import { ProtectedRoute } from "~/components/ProtectedRoute";
import { Loading } from "~/components/Spinner";
import { SubscriberRoute } from "~/components/SubscriberRoute";
import { routes } from "~/constants/routes";
import { useAuth } from "~/contexts/AuthContext";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useRodaSubscription } from "~/contexts/SubscriptionContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { AuthError } from "~/pages/AuthError";
import { CheckInSummary } from "~/pages/CheckInSummary";
import { CompanySettings } from "~/pages/CompanySettings";
import { CustomiseFlywheel } from "~/pages/CustomiseFlywheel";
import { CustomiseFlywheelGoalScreen } from "~/pages/CustomiseFlywheelGoal";
import { CustomiseMetricScreen } from "~/pages/CustomiseMetric";
import { EditMember } from "~/pages/EditMember";
import { FlywheelGoalDetail } from "~/pages/FlywheelGoalDetail";
import { GettingStarted } from "~/pages/GettingStarted";
import { Home } from "~/pages/Home";
import { Members } from "~/pages/Members";
import { MetricDetail } from "~/pages/MetricDetail";
import { NewFlywheelSetupComplete } from "~/pages/NewFlywheelSetupComplete";
import { ReadyToStart } from "~/pages/ReadyToStart";
import { ReviewIntro } from "~/pages/review/Review";
import { ReviewFlywheelGoal } from "~/pages/review/ReviewFlywheelGoal";
import { ReviewMetric } from "~/pages/review/ReviewMetric";
import { ReviewMetrics } from "~/pages/review/ReviewMetrics";
import { CompanyDashboard } from "~/pages/roda-admin/CompanyDashboard";
import { CompanyManagement } from "~/pages/roda-admin/CompanyManagement";
import { Step } from "~/pages/Step";
import { UserProfile } from "~/pages/UserProfile";

export const Pages = () => {
  const { user, fetching } = useCurrentUser();
  const { authenticated } = useAuth();
  const { renderPaymentPrompt } = useRodaSubscription();
  const { currentCompany, isAdmin } = useCurrentCompanyContext();

  if (fetching && !user) return <Loading.AbsoluteCenter className="size-6" />;

  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;

  return (
    <main className="flex flex-col flex-1 bg-white relative min-h-container overflow-auto">
      {!!currentCompany && renderPaymentPrompt()}

      <Routes>
        <Route
          path={routes.organisationManagement}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <Outlet />
            </ProtectedRoute>
          )}
        >
          <Route
            path=""
            index
            element={(
              <ProtectedRoute isAllowed={authenticated && isRodaAdmin}>
                <CompanyManagement />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.companySettings(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && isRodaAdmin}>
                <CompanyDashboard />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.checkIn(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated}>
                <CheckInSummary />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.review(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <ReviewIntro />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.organisationSettings(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <CompanySettings />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.members(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <Members />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.editMember(":userId", ":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <EditMember />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.editFlywheelGoal(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated}>
                <CustomiseFlywheelGoalScreen />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.createFlywheel(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && isAdmin}>
                <Onboarding
                  createFlywheelMode
                  firstStep="flywheel_intro"
                />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.dashboard(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated}>
                <FlywheelContainer />
              </ProtectedRoute>
            )}
          >
            <Route
              path={routes.step(":stepId", ":companyId")}
              element={(
                <Step />
              )}
            />

            <Route
              path={routes.flywheelGoal(":companyId")}
              element={(
                <FlywheelGoalDetail />
              )}
            />

            <Route
              path={routes.stepMetric(":stepId", ":metricId", ":companyId")}
              element={(
                <MetricDetail />
              )}
            />

          </Route>

          <Route
            path={routes.customiseMetric(":stepId", ":metricId", ":companyId")}
            element={(
              <CustomiseMetricScreen />
            )}
          />

          <Route
            path={routes.addMetric(":stepId", ":companyId")}
            element={(
              <CustomiseMetricScreen />
            )}
          />

          <Route
            path={routes.customiseFlywheel(":companyId")}
            element={(
              <CustomiseFlywheel />
            )}
          />

          <Route
            path={routes.reviewMetrics(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <ReviewMetrics />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.reviewMetric(":metricId", ":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <ReviewMetric />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.reviewFlywheelGoal(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <ReviewFlywheelGoal />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.review(":companyId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && (isRodaAdmin || isAdmin)}>
                <ReviewIntro />
              </ProtectedRoute>
            )}
          />
        </Route>

        {/* End of routes.organisationManagement */}

        <Route
          path={routes.checkIn()}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <SubscriberRoute>
                <CheckInSummary />
              </SubscriberRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.editFlywheelGoal()}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <SubscriberRoute>
                <CustomiseFlywheelGoalScreen />
              </SubscriberRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.reviewMetrics()}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <SubscriberRoute>

                <ReviewMetrics />
              </SubscriberRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.readyToStart}
          element={(
            <ProtectedRoute isAllowed={authenticated}>

              <ReadyToStart />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.newFlywheelSetupComplete}
          element={(
            <ProtectedRoute isAllowed={authenticated && isAdmin}>

              <NewFlywheelSetupComplete />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.reviewMetric(":metricId")}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <SubscriberRoute>

                <ReviewMetric />
              </SubscriberRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.reviewFlywheelGoal()}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <SubscriberRoute>

                <ReviewFlywheelGoal />
              </SubscriberRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.review()}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <SubscriberRoute>

                <ReviewIntro />
              </SubscriberRoute>
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.account}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <UserProfile />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.dashboard()}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <FlywheelContainer />
            </ProtectedRoute>
          )}
        >
          <Route
            path={routes.step(":stepId")}
            element={(
              <SubscriberRoute>

                <Step />
              </SubscriberRoute>
            )}
          />

          <Route
            path={routes.flywheelGoal()}
            element={(
              <SubscriberRoute>

                <FlywheelGoalDetail />
              </SubscriberRoute>
            )}
          />

          <Route
            path={routes.stepMetric(":stepId", ":metricId")}
            element={(
              <SubscriberRoute>

                <MetricDetail />
              </SubscriberRoute>
            )}
          />

        </Route>

        <Route
          path={routes.customiseMetric(":stepId", ":metricId")}
          element={(
            <SubscriberRoute>

              <CustomiseMetricScreen />
            </SubscriberRoute>
          )}
        />

        <Route
          path={routes.addMetric(":stepId")}
          element={(
            <SubscriberRoute>

              <CustomiseMetricScreen />
            </SubscriberRoute>
          )}
        />

        <Route
          path={routes.gettingStarted}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <GettingStarted />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.organisationSettings()}
          element={(
            <ProtectedRoute isAllowed={authenticated && isAdmin}>
              <CompanySettings />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.members()}
          element={(
            <ProtectedRoute isAllowed={authenticated && isAdmin}>
              <Outlet />
            </ProtectedRoute>
          )}
        >
          <Route
            index
            path={routes.members()}
            element={(
              <ProtectedRoute isAllowed={authenticated && isAdmin}>
                <Members />
              </ProtectedRoute>
            )}
          />

          <Route
            path={routes.editMember(":userId")}
            element={(
              <ProtectedRoute isAllowed={authenticated && isAdmin}>
                <EditMember />
              </ProtectedRoute>
            )}
          />

        </Route>

        <Route
          path={routes.onboarding({ newCompany: true })}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <Onboarding />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.onboarding({ newCompany: false })}
          element={(
            <ProtectedRoute isAllowed={authenticated}>
              <Onboarding />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.createFlywheel()}
          element={(
            <ProtectedRoute isAllowed={authenticated && isAdmin}>
              <Onboarding
                createFlywheelMode
                firstStep="flywheel_intro"
              />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.customiseFlywheel()}
          element={(
            <ProtectedRoute isAllowed={authenticated && isAdmin}>
              <CustomiseFlywheel />
            </ProtectedRoute>
          )}
        />

        <Route
          path={routes.home}
          element={<Home />}
        />

        <Route
          path={routes.authError}
          element={<AuthError />}
        />

        <Route
          path="*"
          element={<Navigate to={routes.home} />}
        />
      </Routes>
    </main>
  );
};