import { enumUserCompanyRole } from "@roda/graphql/genql";
import { removeEmojis } from "@roda/shared/utils";
import { z } from "zod";

import { removeExtraSpaces } from "~/utils/validation";

import { UserAccountFieldType } from "src/types";

export const StepAliasSchema = z.object({
  stepId: z.string(),
  alias: z.string().trim().max(25, "Maximum character count exceeded").transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" })
});

export const MetricTargetSchema = (cap: number | null | undefined) => z.object({
  target: z.string().trim().min(1, "Required").max(40, "Maximum 40 digits").regex(/^[\d.]+$/, "Please choose a valid target").refine(input => {
    if (!!cap && !!input && Number(input) > cap) return false;

    return true;
  }, `Target must be ${cap} or lower`)
});

export const FlywheelGoalSchema = z.object({ goal: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Please choose a valid goal") });

export const FlywheelGoalOwnerSchema = z.object({ ownerId: z.coerce.number().positive({ message: "Please choose an owner" }) });

export const FlywheelSubgoalSchema = z.object({ goal: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Please choose a valid goal") });

export const ReviewFlywheelGoalSchema = z.object({
  // The name of the goal
  name: z.string().trim().min(1, "Required").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  // The main year goal
  goal: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Invalid number"),
  // The goal for the next quarter
  subgoal: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Invalid number")
});

export const ReviewMetricSchema = (cap: number | null | undefined) => z.object({
  // The metric owner
  ownerId: z.coerce.number().positive({ message: "Please choose an owner" }).optional(),
  // The goal for the metric
  goal: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Please choose a valid goal").refine(input => {
    if (!!cap && !!input && Number(input) > cap) return false;

    return true;
  }, `Target must be ${cap} or lower`)
});

export const MetricSchema = z.object({
  metricName: z.string().trim().min(1, "Required").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitName: z.string().trim().min(1, "Required").max(100, "Maximum 100 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitDisplay: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitDescription: z.string().trim().min(1, "Required").max(400, "Maximum 400 characters").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  unitType: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters"),
  unitTypeLabel: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters"),
  unitTargetType: z.string().trim().min(1, "Required").max(50, "Maximum 50 characters"),
  cap: z.coerce.number().optional(),
  ownerId: z.coerce.number().positive({ message: "Please choose an owner" }),
  target: z.string().trim().min(1, "Required").max(40, "Maximum 40 digits").regex(/^[\d.]+$/, "Invalid number"),
  reportingWindowTiming: z.string().max(50, "Maximum 50 characters").trim().optional(),
  slug: z.string().trim().optional()
});

export const UserProfileSchema = z.object({
  [ UserAccountFieldType.FirstName ]: z.string().trim().max(75, "Maximum character count exceeded").transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  [ UserAccountFieldType.LastName ]: z.string().trim().max(75, "Maximum character count exceeded").transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  [ UserAccountFieldType.Email ]: z.string().trim().min(1, "Required").max(100, "Maximum character count exceeded").email().transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  [ UserAccountFieldType.JobTitle ]: z.string().trim().max(75, "Maximum character count exceeded").transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" })
});

export const FlywheelGoalCheckInSchema = z.object({
  value: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Invalid number").refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }),
  notes: z.string().refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }).nullable()
});

export const EditMemberSchema = z.object({
  email: z.string().trim().min(1, "Required").email().max(100, "Maximum 100 characters"),
  role: z.enum([ enumUserCompanyRole.ADMIN, enumUserCompanyRole.USER ]).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" })
});

export const EmailInputSchema = z.object({ email: z.string().trim().email().max(100, "Maximum 100 characters") });

export const MetricCheckInSchema = z.object({
  value: z.string().trim().min(1, "Required").regex(/^[\d.]+$/, "Invalid number"),
  notes: z.string().trim().refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" }).nullable()
});

export const CompanySettingsSchema = z.object({
  name: z.string().trim().min(1, "Required").max(50).transform(removeExtraSpaces).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" })
});

export const InviteMemberSchema = z.object({
  email: z.string().trim().email().max(100, "Maximum 100 characters"),
  role: z.enum([ enumUserCompanyRole.ADMIN, enumUserCompanyRole.USER ]).refine(value => {
    const sanitized = removeEmojis(value);

    return sanitized === value;
  }, { message: "Emojis are not allowed" })
});
