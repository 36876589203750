import {
  UserRole,
  FlywheelTemplateUnitTypeLabelEnum,
  FlywheelTemplateReportingWindowTimingEnum
} from "@roda/shared/types";
import clsx from "clsx";
import {
  useMemo,
  useState
} from "react";

import { Avatar } from "~/components/Avatar";
import { Button } from "~/components/Button";
import { MetricCheckInPopup } from "~/components/check-in/MetricCheckInPopup";
import { Icon } from "~/components/Icon";
import { EditMetricCardMenu } from "~/components/metric/EditMetricCardMenu";
import { MetricProgressChart } from "~/components/metric/MetricProgressChart";
import { Loading } from "~/components/Spinner";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import dayjs from "~/utils/dayjs";
import { formatGoalNumber } from "~/utils/formatGoalNumber";
import { getUnitSymbol } from "~/utils/getUnitSymbol";
import { getUserDisplayName } from "~/utils/getUserDisplayName";
import { getWeekMetricUpdate } from "~/utils/getWeekMetricUpdate";
import { getWeekNumber } from "~/utils/getWeekNumber";

import type { Metric } from "@roda/graphql/genql";
import type { Dayjs } from "dayjs";

interface MetricCardProps {
  metric: Metric;
  hideTitle?: boolean;
  selectedWeekStart?: Dayjs;
  showUser?: boolean;
  onCheckInChange?: (isOpen: boolean) => void;
}

export const MetricCard: React.FC<MetricCardProps> = ({
  metric, hideTitle, selectedWeekStart, showUser = true, onCheckInChange
}) => {
  const {
    flywheel, flywheelStartWeek, flywheelCycleNotStarted, flywheelSubgoals
  } = useSelectedFlywheel();

  const metricUnitSymbol = getUnitSymbol(metric.unitTypeLabel, flywheel?.currency, true);
  const weekStart = useMemo(() => selectedWeekStart || dayjs().subtract(1, "week").startOf("isoWeek"), [ selectedWeekStart ]);
  const weekEnd = useMemo(() => weekStart.endOf("isoWeek"), [ weekStart ]);
  const currentQuarterIdx = useMemo(() => flywheelSubgoals?.findIndex(s => weekStart.isBetween(s.startDate, s.endDate, "days", "[]")), [ flywheelSubgoals, weekStart ]);
  const { user } = useCurrentUser();
  const { isAdmin } = useCurrentCompanyContext();

  // Check if the current user can update the metric, only admins, roda admins and the owner of the metric can update it
  const userCanUpdateMetric = useMemo(() => metric.owner?.id === user?.id || isAdmin || user?.role === UserRole.RODA_ADMIN, [
    metric.owner?.id,
    user,
    isAdmin
  ]);

  // These can be null if we haven't fetched them yet
  const metricUpdatesIsEmpty = !metric.metricUpdates?.length;
  const metricTargetsIsEmpty = !metric.targets?.length;

  const weekUpdate = useMemo(() => metric.metricUpdates && getWeekMetricUpdate(metric.metricUpdates, weekStart, weekEnd), [
    metric.metricUpdates,
    weekEnd,
    weekStart
  ]);

  const isCheckInDue = useMemo(() => !weekUpdate && !flywheelCycleNotStarted, [ weekUpdate, flywheelCycleNotStarted ]);
  const currentMetricTarget = metric.targets?.find(target => target.isCurrent);

  const currentTarget = useMemo(() => weekUpdate?.metricTarget?.target || currentMetricTarget?.target,
    [ currentMetricTarget, weekUpdate ]
  );

  // Get the display text we show per unit
  // If the reporting window timing is NOT quarter to date then default to "this week", otherwise show "this quarter to date"
  const unitDisplayText = useMemo(() => `${metric.unitName} ${metric.reportingWindowTiming !== FlywheelTemplateReportingWindowTimingEnum.QUARTER_TO_DATE ? "this week" : "this quarter to date"}`, [ metric.reportingWindowTiming, metric.unitName ]);
  const [ isCheckInOpen, setIsCheckInOpen ] = useState(false);
  const textColour = isCheckInDue ? "text-brand-check-in-due-900" : "text-brand-cold-metal-800";

  return (
    <div className="flex flex-col justify-between w-full p-6 gap-2 h-full">

      <div className="flex flex-row justify-between items-start antialiased">
        {!hideTitle ? (
          <div className="flex flex-col items-baseline gap-1">
            <p
              className={clsx("font-semibold flex-1 text-lg leading-snug text-balance xl:text-xl", textColour)}
            >
              {metric.name}
            </p>

            <div className="antialiased">
              <p className={clsx("text-sm font-medium", textColour)}>{unitDisplayText}</p>
            </div>
          </div>
        ) : (
          <div className="antialiased">
            <p className={clsx("text-sm font-medium", textColour)}>{unitDisplayText}</p>
          </div>
        )}

        <EditMetricCardMenu
          metric={metric}
          editableUpdate={weekUpdate}
          buttonClassName={`m-0 -mr-4 md:-mr-8 -mt-3 ${isCheckInDue ? "text-brand-check-in-due-200 hover:text-brand-check-in-due-500" : "text-brand-cold-metal-400 hover:text-black"}`}
        />

      </div>

      {(metricTargetsIsEmpty && metricUpdatesIsEmpty) ? (
        <Loading.Spinner />
      ) : (
        <>
          <div className="w-full flex flex-col *:min-w-0 *:min-h-0">
            {/* Show the check-in button if an update hasn't been posted for the current week, and the user has permissions to update the metric */}
            {isCheckInDue && userCanUpdateMetric ? (
              <div className="flex basis-[42px] items-center justify-stretch">
                <Button
                  title="Check-in required"
                  iconComponent={<Icon.Clock className="size-3" />}
                  className="bg-brand-check-in-due-700 min-w-[100px] h-[30px] p-4 w-full z-10 text-xs text-brand-cold-metal-50 rounded-md font-semibold antialiased"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsCheckInOpen(true);
                    onCheckInChange?.(true);
                  }}
                />
              </div>
            ) : (
              <div className="flex items-baseline gap-2">
                <p className={`flex-1 text-2xl sm:text-4xl font-semibold ${weekUpdate ? "text-brand-cold-metal-800" : isCheckInDue ? "text-brand-check-in-due-100" : "text-brand-cold-metal-300"}`}>{weekUpdate ? formatGoalNumber(Number(weekUpdate.value), metricUnitSymbol, {
                  shouldCompact: true,
                  stripTrailingZeros: true
                }) : "No data"}
                </p>
              </div>
            )}

            <div className={`w-full flex flex-center ${isCheckInDue ? "mt-4" : "mt-2"}`}>
              <MetricProgressChart
                metric={metric}
                selectedWeekStart={selectedWeekStart}
                hideGraph={!!selectedWeekStart}
              />
            </div>
          </div>

          <div className={clsx(`flex [&:not(&:empty)]:mt-4 text-sm gap-4 ${metric.unitDisplay !== "quarterly" && selectedWeekStart ? "flex-col-reverse items-start" : "flex-row items-center"} justify-between w-full *:min-w-0`)}>
            {showUser && (
              <div className="flex-1">
                {metric.owner && (
                  <div className="flex flex-row items-center gap-2">
                    <Avatar
                      user={metric.owner}
                      px={24}
                    />

                    <p
                      className={clsx("truncate text-sm max-w-[170px]", `${isCheckInDue ? "text-brand-check-in-due-900" : "text-brand-cold-metal-500"}`)}
                    >
                      {getUserDisplayName(metric.owner)}
                    </p>
                  </div>
                )}
              </div>
            )}

            {metric.unitDisplay !== "quarterly" && (
              <div
                className={clsx(" font-semibold shrink-0", isCheckInDue ? "text-brand-check-in-due-800" : "text-brand-cold-metal-600")}
              >
                <p>{`Q${currentQuarterIdx ? currentQuarterIdx + 1 : 1} target ${metric.unitTypeLabel === FlywheelTemplateUnitTypeLabelEnum.CURRENCY ? `${metricUnitSymbol}${currentTarget}` : `${currentTarget}${metricUnitSymbol}`}`}</p>
              </div>
            )}

          </div>
        </>
      )}

      <MetricCheckInPopup
        isOpen={isCheckInOpen}
        onClose={() => {
          setIsCheckInOpen(false);
          onCheckInChange?.(false);
        }}
        metric={metric}
        weekStart={weekStart.format("YYYY-MM-DD")}
        weekNumber={getWeekNumber({
          flywheelStartWeek,
          date: weekStart
        })}
      />
    </div>
  );
};