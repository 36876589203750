import { UserRole } from "@roda/shared/types";
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Button } from "~/components/Button";
import { CalloutBadge } from "~/components/CalloutBadge";
import { MetricPreview } from "~/components/customise-metric/MetricPreview";
import { SelectCustomiseMetric } from "~/components/customise-metric/SelectCustomiseMetric";
import { Icon } from "~/components/Icon";
import { FlywheelPreview } from "~/components/onboarding/metrics-and-targets/FlywheelPreview";
import { StepOverview } from "~/components/onboarding/metrics-and-targets/StepOverview";
import type { OnboardingContentStepProps } from "~/components/onboarding/OnboardingContent";
import { OnboardingContentWrapper } from "~/components/onboarding/OnboardingContentWrapper";
import { Loading } from "~/components/Spinner";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import {
  useCustomiseMetricDispatch,
  useCustomiseMetricState
} from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import type { MetricType } from "~/contexts/CustomiseMetricContext/metric-reducer";
import {
  useOnboardingDispatch,
  useOnboardingState
} from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useCreateStepsWithMetrics } from "~/hooks/step";
import { useIsMobile } from "~/hooks/useIsMobile";

import type {
  MetricWithoutStepId,
  StepWithMetrics
} from "@roda/core/step/createStepsWithMetrics";

export const MetricsAndTargets: React.FC<OnboardingContentStepProps> = ({
  changeStep, onBack, createFlywheelMode
}) => {
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const { flywheel: onboardingFlywheel, company: companyFromOnboardingState } = useOnboardingState();
  const { currentCompany, refetchCurrentCompany } = useCurrentCompanyContext();
  const setOnboardingDispatch = useOnboardingDispatch();
  const setCustomiseMetricDispatch = useCustomiseMetricDispatch();
  const { finishFlywheelSetup } = useSelectedFlywheel();
  const navigate = useNavigate();
  const { refetch: refetchCurrentUser, user } = useCurrentUser();
  const [ loading, setLoading ] = useState(false);
  const [ { fetching }, saveProgress ] = useCreateStepsWithMetrics();
  const [ hasEdited, setHasEdited ] = useState(false);
  const company = companyFromOnboardingState || currentCompany;

  const {
    metric, stage, step
  } = useCustomiseMetricState();

  const isMobile = useIsMobile();
  const flywheelContainerRef = useRef<HTMLDivElement>(null);
  const [ flywheelSize, setFlywheelSize ] = useState(0);

  useEffect(() => {
    const updateFlywheelSize = () => {
      if (flywheelContainerRef.current) {
        const containerHeight = Math.min(flywheelContainerRef.current.offsetHeight, flywheelContainerRef.current.offsetWidth * 0.5); // slightly smaller than other screen since this one has annotations
        const size = containerHeight - 60;

        setFlywheelSize(size);
      }
    };

    // Call the update function initially and on window resize
    updateFlywheelSize();
    window.addEventListener("resize", updateFlywheelSize);

    return () => {
      window.removeEventListener("resize", updateFlywheelSize);
    };
  }, [ isMobile, flywheelContainerRef.current?.offsetHeight ]);

  /**
   * Checks if the onboarding steps are 5
   * and whether all of them have at least 1 metric
   */
  const hasMinMetricsPerStep = useMemo(() => {
    const hasMin = onboardingFlywheel?.steps?.every(step => (step?.metrics?.length || 0) >= 1) || false;

    return hasMin;
  }, [ onboardingFlywheel?.steps ]);

  const onboardingSteps = useMemo(() => onboardingFlywheel?.steps ?? [], [ onboardingFlywheel?.steps ]);

  const onAddMetric = (metric: MetricType) => {
    if (step?.index !== undefined) {
      setOnboardingDispatch({
        type: "ADD_OR_UPDATE_METRIC",
        metric,
        stepIdx: step.index
      });
      setHasEdited(true);
      setCustomiseMetricDispatch({ type: "RESET" });
    }
  };

  const onDeleteMetric = (metric: MetricType) => {
    if (step?.index !== undefined) {
      setOnboardingDispatch({
        type: "REMOVE_METRIC",
        metric,
        stepIdx: step.index
      });
      setHasEdited(true);
      setCustomiseMetricDispatch({ type: "RESET" });
    }
  };

  useEffect(() => {
    if (hasEdited) {
      setHasEdited(false);
      handleSaveProgress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ onboardingFlywheel?.steps, hasEdited ]);

  const handleSaveProgress = useCallback(async () => {
    if (!onboardingFlywheel) {
      return null;
    }

    // Construct the steps array for batch creating steps with metrics
    const steps: StepWithMetrics[] = onboardingFlywheel && onboardingFlywheel.id !== undefined && onboardingSteps?.length !== undefined ? onboardingSteps.map((step, idx) => {
      const metrics = step?.metrics?.map(({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        metricIdx, stepId, ...metricWithoutIdx
      }) => metricWithoutIdx);

      return {
        flywheelId: +onboardingFlywheel.id!,
        order: step.order || idx,
        name: step?.name || "",
        alias: step?.alias || "",
        metrics: metrics as MetricWithoutStepId[] || []
      };
    }) : [];

    if (company?.id && user?.email) {
      toast.dismiss("progress-saved");

      saveProgress({
        completedOnboarding: 0,
        steps: steps?.map((step, idx) => {
          return {
            flywheelId: +onboardingFlywheel.id!,
            order: step.order || idx,
            name: step?.name || "",
            alias: step?.alias || "",
            metrics: step.metrics?.map(metric => ({
              fromDate: metric.fromDate,
              name: metric.name,
              ownerEmail: metric.ownerEmail,
              target: metric.target,
              unitDescription: metric.unitDescription,
              unitDisplay: metric.unitDisplay!,
              unitTargetType: metric.unitTargetType,
              unitType: metric.unitType,
              unitTypeLabel: metric.unitTypeLabel,
              cap: metric.cap,
              reportingWindowTiming: metric.reportingWindowTiming,
              slug: metric.slug,
              toDate: metric.toDate,
              unitName: metric.unitName!
            })) || []
          };
        }) || [],
        setupComplete: 0,
        companyId: +company?.id
      }).then(() => {
        toast.success("Progress saved.", {
          id: "progress-saved",
          position: "top-center"
        });
      });
    }
  }, [
    onboardingFlywheel,
    onboardingSteps,
    company?.id,
    user?.email,
    saveProgress
  ]);

  const handleCompleteSetup = useCallback(async () => {
    setLoading(true);

    // Construct the steps array for batch creating steps with metrics
    const steps = onboardingFlywheel && onboardingFlywheel.id !== undefined && onboardingSteps?.length !== undefined ? onboardingSteps.map((step, idx) => {
      const metrics = step?.metrics?.map(({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        metricIdx, stepId, ...metricWithoutIdx
      }) => metricWithoutIdx);

      return {
        flywheelId: +onboardingFlywheel.id!,
        order: step.order || idx,
        name: step?.name || "",
        alias: step?.alias || "",
        metrics: metrics || []
      };
    }) : [];

    if (company?.id && user?.email) {
      finishFlywheelSetup({
        steps: steps.map(step => ({
          ...step,
          metrics: step.metrics.map(metric => ({
            cap: metric.cap,
            reportingWindowTiming: metric.reportingWindowTiming,
            slug: metric.slug,
            toDate: metric.toDate,
            fromDate: metric.fromDate || "",
            name: metric.name || "",
            ownerEmail: metric.ownerEmail || "",
            target: metric.target || "",
            unitDescription: metric.unitDescription || "",
            unitDisplay: metric.unitDisplay || "",
            unitTargetType: metric.unitTargetType || "",
            unitType: metric.unitType || "",
            unitTypeLabel: metric.unitTypeLabel || "",
            unitName: metric.unitName || ""
          }))
        })),
        setupComplete: 1,
        companyId: +company?.id
      }, () => {
        setLoading(false);

        if (user?.role === UserRole.RODA_ADMIN) {
          refetchCurrentCompany();
        } else {
          refetchCurrentUser();
        }

        // Reset onboarding state - don't need it anymore
        setOnboardingDispatch({ type: "RESET" });

        currentCompany?.completedOnboarding ?
          // Navigate to flywheel setup complete if we're on createFlywheel mode
          navigate(routes.newFlywheelSetupComplete, { replace: true }) :
          navigate(routes.readyToStart, { replace: true });
      }, onboardingFlywheel?.id);
    }
  }, [
    onboardingFlywheel,
    onboardingSteps,
    refetchCurrentCompany,
    user,
    finishFlywheelSetup,
    currentCompany,
    refetchCurrentUser,
    setOnboardingDispatch,
    company?.id,
    navigate
  ]);

  return (
    <OnboardingContentWrapper
      createFlywheelMode={createFlywheelMode}
      hideLogo={createFlywheelMode}
      hideTitle={!!stage}
      changeStep={changeStep}
      leftContent={!stage ? (
        <div className="w-full flex flex-col flex-1 pb-10">
          <p className=" opacity-80">
            <strong>Steps</strong> are the sequential, repeatable outcomes needed to achieve your flywheel goals.

            {" "}

            <strong>Metrics</strong> are measurable indicators that show if you're on track. You can adjust these at anytime.
          </p>

          {onboardingSteps.length ? onboardingSteps.sort((a, b) => a.order! - b.order!)?.map(step => (
            <StepOverview
              key={step?.stepIdx}
              step={step}
            />
          )) : (
            <div>

              <p className={`${isMobile ? "text-xs" : "text-base"} opacity-80 mt-4 mb-2`}>You don't have any steps yet.
              </p>

              <div
                className="mb-4 cursor-pointer"
                onClick={() => {
                  customiseFlywheelGoalDispatch({ type: "CUSTOMISE_TEMPLATE" });

                  setOnboardingDispatch({
                    type: "SET_STEP",
                    step: "flywheel_goal"
                  });
                }}
              >
                <CalloutBadge variant="info">
                  Click here to setup your steps.
                </CalloutBadge>
              </div>
            </div>

          )}

          {!!onboardingSteps.length && (

            <div className="flex flex-row justify-between gap-2">
              <Button
                title="Back"
                onClick={onBack}
              />

              <Button
                title="Finish setup"
                onClick={handleCompleteSetup}
                loading={loading}
                disabled={!hasMinMetricsPerStep}
              />
            </div>
          )}
        </div>
      ) : (
        <SelectCustomiseMetric
          onSave={onAddMetric}
          onDelete={onDeleteMetric}
          flywheelTemplateId={onboardingFlywheel?.flywheelTemplateId}
        />
      )}
      rightContent={(
        <div
          ref={flywheelContainerRef}
          className={`relative flex-1 grid place-items-center ${isMobile ? "h-full max-h-[1000px] w-full max-w-[1000px] overflow-x-visible pb-10" : "h-screen overflow-visible"}`}
        >
          {fetching && (
            <div className="absolute top-4 left-4">
              <Loading.Spinner />
            </div>
          )}

          {stage ?
            (
              <MetricPreview metric={metric} />
            ) :
            (
              <div>
                <FlywheelPreview
                  flywheelSize={flywheelSize}
                  availableSteps={onboardingFlywheel?.steps}
                />

                <Button
                  className="lg:bottom-10 absolute -translate-x-1/2  bg-transparent rounded-full text-brand-cold-metal-500 border border-brand-cold-metal-300 border-solid"
                  title="Edit steps"
                  onClick={() => {
                    customiseFlywheelGoalDispatch({ type: "CUSTOMISE_TEMPLATE" });

                    setOnboardingDispatch({
                      type: "SET_STEP",
                      step: "flywheel_goal"
                    });
                  }}
                  iconComponent={<Icon.Edit />}
                />
              </div>
            )}
        </div>
      )}
    />

  );
};