import { useTypedQuery } from "@roda/graphql/urql";

import { ReadOnlyUser } from "~/fragments/index";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export type ListCompaniesResponse = NonNullable<NonNullable<ReturnType<typeof useListCompanies>["0"]["data"]>["listCompanies"]>;

export const useListCompanies = (opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      listCompanies: {
        id: true,
        name: true,
        createdAt: true,
        updatedAt: true,
        completedOnboarding: true,
        logoS3Key: true,
        logoS3Bucket: true,
        industryId: true,
        activeUserCount: true,
        invitedUserCount: true,
        flywheels: {
          id: true,
          setupComplete: true,
          companyId: true,
          deletedAt: true,
          name: true
        },
        lastSeenUser: {
          ...ReadOnlyUser,
          lastSeenAt: true
        }
      }
    }
  });
};