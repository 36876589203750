import { useTypedQuery } from "@roda/graphql/urql";

type UseTypedQueryOpts = Omit<Parameters<typeof useTypedQuery>[0], "query">;

export type ListUsersResponse = ReturnType<typeof useListUsers>[0]["data"];
export type ListUsersType = NonNullable<ListUsersResponse>["listUsers"];

export const useListUsers = (companyId?: number, opts?: UseTypedQueryOpts) => {
  return useTypedQuery({
    ...opts,
    query: {
      listUsers: {
        __args: { companyId: companyId! },
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        status: true,
        jobTitle: true,
        role: true,
        verified: true,
        userCompany: {
          id: true,
          companyId: true,
          role: true
        },
        isMetricOwner: true,
        updatedAt: true,
        createdAt: true,
        avatarS3Key: true,
        avatarS3Bucket: true,
        lastSeenAt: true
      }
    }
  });
};