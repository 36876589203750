import { UserRole } from "@roda/shared/types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { CalloutBadge } from "~/components/CalloutBadge";
import { ConfirmationPopup } from "~/components/ConfirmationPopup";
import { Icon } from "~/components/Icon";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { useFlywheelLoader } from "~/contexts/FlywheelLoaderContext";
import { useOnboardingDispatch } from "~/contexts/OnboardingContext/OnboardingContext";
import { useSelectedFlywheel } from "~/contexts/SelectedFlywheelContext";
import { useRodaSubscription } from "~/contexts/SubscriptionContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useDeleteFlywheel } from "~/hooks/flywheel/use-delete-flywheel";
import { useError } from "~/hooks/useError";

import * as SideNav from "./side-navigation/components";

export const FlywheelMenu: React.FC<{onClick?: () => void}> = ({ onClick }) => {
  const { user, refetch: refetchSessionWithFlywheels } = useCurrentUser();

  const {
    currentCompany, isAdmin, refetchCurrentCompany
  } = useCurrentCompanyContext();

  const { activeOrTrialing, isSubscriptionActive } = useRodaSubscription();
  const isRodaAdmin = user?.role === UserRole.RODA_ADMIN;
  const { flywheel, setActiveFlywheelId } = useSelectedFlywheel();
  const onboardingDispatch = useOnboardingDispatch();
  const customiseMetricDispatch = useCustomiseMetricDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const [ flywheelIdToDelete, setFlywheelIdToDelete ] = useState<number | null>(null);

  const {
    availableFlywheels, draftFlywheels, onDeleteFlywheel
  } = useFlywheelLoader();

  const { assertGraphQLSuccess } = useError();
  const [ deletionState, deleteFlywheelMutation ] = useDeleteFlywheel();
  const navigate = useNavigate();

  const deleteFlywheel = async () => {
    if (flywheelIdToDelete) {
      const res = await deleteFlywheelMutation({ flywheelId: flywheelIdToDelete });

      setFlywheelIdToDelete(null);
      assertGraphQLSuccess(res);

      if (user?.role === UserRole.RODA_ADMIN) {
        refetchCurrentCompany();
      } else {
        refetchSessionWithFlywheels(); // this will refetch the current session
      }

      onDeleteFlywheel(flywheelIdToDelete);

      if (res.data?.softDeleteFlywheel.deletedAt) {
        const remainingFlywheel = availableFlywheels?.find(fw => fw.id !== res.data?.softDeleteFlywheel.id);

        if (remainingFlywheel) {
          setActiveFlywheelId(remainingFlywheel?.id);
        }

        navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
      }
    }
  };

  const navigateToDashboard = () => {
    navigate(routes.dashboard(currentCompany ? currentCompany.id : undefined));
    onClick?.();
  };

  const navigateToCreateFlywheel = () => {
    navigate(routes.createFlywheel(currentCompany ? currentCompany.id : undefined));
    onClick?.();
  };

  const navigateToCustomiseFlywheel = () => {
    navigate(routes.customiseFlywheel(currentCompany ? currentCompany.id : undefined));
    onClick?.();
  };

  const allowedToCreateFlywheel = isAdmin && (isSubscriptionActive || activeOrTrialing);

  if (!availableFlywheels) {
    return null;
  }

  return (
    <>
      <SideNav.ExpandableNavItem
        label="Flywheels"
        icon={<Icon.Refresh className="w-4.5 h-4.5" />}
        onClick={() => null}
        expandableOptions={[
          ...availableFlywheels.map(fl => (
            <SideNav.ExpandedNavItemOption
              key={`availableFlywheel_${fl.id}`}
              label={fl.name}
              onClick={() => {
                setActiveFlywheelId(fl.id);
                navigateToDashboard();
              }}
              isSelected={flywheel?.id === fl.id}
              editAction={(allowedToCreateFlywheel || isRodaAdmin) ? () => {
                setActiveFlywheelId(fl.id);
                navigateToCustomiseFlywheel();
              } : undefined}
            />
          )),
          ...(draftFlywheels?.map(fl => (
            <SideNav.ExpandedNavItemOption
              key={`draftFlywheel_${fl.id}`}
              hidden={!allowedToCreateFlywheel}
              label={`${fl.name} (draft)`}
              iconMode="delete"
              editAction={(isAdmin || isRodaAdmin) ? () => {
                setFlywheelIdToDelete(+fl.id);
                onClick?.();
              } : undefined}
              onClick={() => {
                customiseMetricDispatch({ type: "RESET" });
                onboardingDispatch({ type: "RESET" });
                customiseFlywheelGoalDispatch({ type: "RESET" });
                setActiveFlywheelId(fl.id);
                navigateToCreateFlywheel();
              }}
              isSelected={flywheel?.id === fl.id}
            />
          ))) || [],
          allowedToCreateFlywheel ? (
            <SideNav.ExpandedNavItemOption
              key="add_flywheel_option"
              label="Add new flywheel"
              onClick={() => {
                customiseMetricDispatch({ type: "RESET" });
                customiseFlywheelGoalDispatch({ type: "RESET" });
                onboardingDispatch({ type: "RESET" });

                onboardingDispatch({
                  type: "SET_STEP",
                  step: "flywheel_intro"
                });
                navigateToCreateFlywheel();
              }}
              isSelected={false}
              icon={<Icon.PlusCircle className="w-4 text-brand-cold-metal-600" />}
            />
          ) : <></>
        ]}
      />

      <ConfirmationPopup
        subtitle={(
          <div className="mb-4">
            <CalloutBadge variant="warning" />
          </div>
        )}
        isOpen={!!flywheelIdToDelete}
        title="Delete flywheel?"
        cancelText="Cancel"
        onCancel={() => setFlywheelIdToDelete(null)}
        onContinue={deleteFlywheel}
        continueText="Yes, delete"
        continueDisabled={deletionState.fetching}
        continueButtonClassName="bg-brand-error-red-500 hover:text-white"
        loading={deletionState.fetching}
        text={(
          <p className="text-pretty">
            Are you sure you want to delete this draft flywheel? This action is irreversible.
          </p>
        )}
      />
    </>

  );
};