import { generateQueryOp } from "@roda/graphql/genql";

import { Flywheel as FlywheelFragment } from "~/fragments/flywheel";

import type { Flywheel } from "@roda/graphql/genql";
import type {
  DataFields,
  Cache,
  Variables,
  ResolveInfo
} from "@urql/exchange-graphcache";

/**
 * Runs after successful createStepsWithMetrics
 * Makes sure that listFlywheels state is up to date with the result of createStepsWithMetrics
 * @returns Updates the normalised cache with the results of the mutation
 */
export function createStepsWithMetrics(result: DataFields, _args: Variables, cache: Cache, _info: ResolveInfo) {
  try {
    // We may not have a previous query in the cache so we're getting the companyId directly from the mutation result
    const companyId = (result.createStepsWithMetrics as unknown as Flywheel[])[ 0 ]?.companyId;

    if (!companyId) {
      return;
    }

    // Regenerate the query, using the same args as last time
    const { query, variables } = generateQueryOp({
      listFlywheels: {
        __args: { companyId },
        ...FlywheelFragment
      }
    });

    // Query our cache for listFlywheels
    cache.updateQuery<{listFlywheels: Flywheel[] | null}>({
      query,
      variables
    }, () => {
      // Finally update our cache with the result of our successful mutation - removing the review
      return { listFlywheels: result.createStepsWithMetrics as unknown as Flywheel[] };
    });
  } catch (error) {
    console.error("error updating cache", error);
  }
}