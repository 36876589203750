import { Subgoal } from "~/fragments/subgoal";

import type { FlywheelGoalGenqlSelection } from "@roda/graphql/genql";

export const FlywheelGoal: FlywheelGoalGenqlSelection = {
  id: true,
  achieveBy: true,
  ownerId: true,
  owner: {
    id: true,
    firstName: true,
    lastName: true,
    email: true,
    jobTitle: true,
    role: true,
    verified: true,
    isMetricOwner: true,
    updatedAt: true,
    createdAt: true,
    avatarS3Key: true,
    avatarS3Bucket: true,
    lastSeenAt: true
  },
  createdBy: true,
  goal: true,
  cap: true,
  name: true,
  unitName: true,
  unitType: true,
  unitTypeLabel: true,
  unitDisplay: true,
  unitDescription: true,
  updateFrequency: true,
  subgoals: Subgoal,
  latestTotalValue: true,
  checkInSubgoal: Subgoal,
  isHealthy: true,
  fromDate: true,
  toDate: true
};
