import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import SetupCompleteImg from "~/assets/illustrations/setup.png";
import { Button } from "~/components/Button";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCurrentUser } from "~/contexts/UserContext";
import { useIsMobile } from "~/hooks/useIsMobile";

export const NewFlywheelSetupComplete: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { user } = useCurrentUser();
  const { isAdmin } = useCurrentCompanyContext();

  // Navigate back to dashboard if not an admin or there's not a user
  useEffect(() => {
    if (!user || !isAdmin) {
      navigate(routes.dashboard());
    }
  }, [
    isAdmin,
    navigate,
    user
  ]);

  return (
    <div
      className={`flex w-full h-[100dvh] ${isMobile ? "flex-col-reverse" : "flex-row"}`}
    >
      <div className={`flex flex-row h-full flex-1 flex-center ${isMobile ? "w-full " : "w-1/2"} `}>

        <div className="w-full flex flex-col gap-4 px-8 py-12 max-w-[500px] flex-1 self-start desktop:self-center">

          <p className={`font-bold ${isMobile ? "text-2xl" : "text-3xl"}`}>Setup complete!</p>

          <p className="text-brand-cold-metal-500 text-[15px] desktop:text-base">
            Your flywheel is all set up and ready to go.
          </p>

          <Button
            title="View my new flywheel"
            onClick={() => navigate(routes.dashboard(), { replace: true })}
            className="px-6 mt-4 text-base sm:text-lg font-medium"
          />

        </div>
      </div>

      {/* Image Container RIGHT SIDE OF SCREEN 50/50 SPLIT */}

      <div className={`relative h-[100dvh] w-1/2 bg-brand-cold-metal-50 ${isMobile ? "w-full h-1/2" : "w-1/2"}`}>
        <img
          src={SetupCompleteImg}
          className="w-full h-full inset-y-0 p-[5vmin] desktop:p-0 absolute object-contain desktop:max-w-[min(35vmax,800px)] desktop:absolute-center"
        />

      </div>

    </div>
  );
};
