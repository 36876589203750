import { useNavigate } from "react-router-dom";

import { CompanyAvatar } from "~/components/CompanyAvatar";
import { SelectInput } from "~/components/form/SelectInput";
import { Icon } from "~/components/Icon";
import { routes } from "~/constants/routes";
import { useCurrentCompanyContext } from "~/contexts/CurrentCompanyContext";
import { useCustomiseFlywheelGoalDispatch } from "~/contexts/CustomiseFlywheelGoalContext/CustomiseFlywheelGoalContext";
import { useCustomiseMetricDispatch } from "~/contexts/CustomiseMetricContext/CustomiseMetricContext";
import { useOnboardingDispatch } from "~/contexts/OnboardingContext/OnboardingContext";
import type { GetCompanyResponse } from "~/hooks/company/use-get-company";

export const CompanySwitcher: React.FC = () => {
  const navigate = useNavigate();

  const {
    currentCompany, companies, setCurrentCompany
  } = useCurrentCompanyContext();

  const customiseMetricDispatch = useCustomiseMetricDispatch();
  const customiseFlywheelGoalDispatch = useCustomiseFlywheelGoalDispatch();
  const onboardingDispatch = useOnboardingDispatch();

  const handleCreateCompany = () => {
    customiseMetricDispatch({ type: "RESET" });
    customiseFlywheelGoalDispatch({ type: "RESET" });
    onboardingDispatch({ type: "RESET" });
    setCurrentCompany(null);
    navigate(routes.onboarding({ newCompany: true }));
  };

  const handleCompanyChange = (companyId: string) => {
    customiseMetricDispatch({ type: "RESET" });
    customiseFlywheelGoalDispatch({ type: "RESET" });
    onboardingDispatch({ type: "RESET" });

    if (companyId === "new") {
      // Navigate to company creation
      handleCreateCompany();

      return;
    }

    const selectedCompany = companies?.find(c => c.id === companyId);

    if (selectedCompany) {
      const companyBeingOnboarded = selectedCompany.flywheels?.length === 0 || !selectedCompany.completedOnboarding;

      if (companyBeingOnboarded) {
        setCurrentCompany(selectedCompany as GetCompanyResponse);
        navigate(routes.onboarding({ newCompany: false }));
      } else {
        setCurrentCompany(selectedCompany as GetCompanyResponse);
        navigate(routes.dashboard(selectedCompany.id));
      }
    }
  };

  // Get list of companies for the dropdown
  const companyOptions = [ ...(companies?.map(c => c.id) || []), "new" ];

  const renderCompanyLabel = (value: string) => {
    if (value === "new") {
      return (
        <div className="flex items-center gap-2 text-brand-cold-metal-600">
          <Icon.PlusCircle className="w-4 h-4" />

          <span>Create company</span>
        </div>
      );
    }

    const company = companies?.find(c => c.id === value);

    if (!company) return;

    return (
      <div className="flex items-center gap-2">
        <CompanyAvatar
          companyName={company.name}
          logoS3Key={company.logoS3Key ?? undefined}
        />

        <span>{company.name}</span>
      </div>
    );
  };

  return (

    <div className="flex-1">
      <SelectInput
        value={currentCompany?.id || ""}
        onValueChange={handleCompanyChange}
        options={companyOptions}
        placeholder="Select a company"
        renderOptionLabel={renderCompanyLabel}
        className="w-full"
      />
    </div>
  );
};